.ems {
    padding-top: 25px;
  }
  .ems h1 {
    margin-bottom: 55px;
  }
  .ems_paragraph {
    text-align: center;
    margin-bottom: 60px;
  }
  .ems_paragraph b {
    display: block;
    font-weight: 600;
  }
  .ems_list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 45px;
  }
  .ems_list li {
    width: 48.5%;
    max-width: 705px;
  }
  .ems_list li img {
    display: block;
    position: relative;
    z-index: 2;
  }
  .ems_list__block {
    position: relative;
    margin-left: 100px;
    padding: 28px 0px 35px 80px;
    z-index: 1;
  }
  .ems_list__block--bg::before {
    content: "";
    position: absolute;
    display: block;
    right: 0;
    bottom: 0;
    background-color: var(--lightgray);
    width: 100%;
    height: 155%;
    z-index: -1;
  }
  .ems_list__block p {
    max-width: 460px;
  }
  .ems_list__sub {
    display: flex;
    align-items: center;
    min-height: 80px;
    font-size: 35px;
    text-align: left;
    margin: 0 0 20px;
  }
  .ems_list li:nth-child(2) .ems_list__sub {
    max-width: 80%;
  }
  .ems_dark {
    width: 100%;
    max-width: 1920px;
    margin: 90px auto 80px;
    background: url(/assets/img/ems_dark.jpg) center center / cover no-repeat;
    color: var(--white);
    padding-top: 65px;
    padding-bottom: 55px;
  }
  .ems_dark .ems_list {
    margin-bottom: 0;
  }
  .ems_gray {
    background-color: var(--lightgray);
    padding: 60px 0 55px;
  }
  .ems_row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .ems_row img {
    display: block;
  }
  .ems_row__img {
    width: 40%;
  }
  .ems_row__text {
    width: 57.5%;
    margin-bottom: 20px;
  }
  .ems_row__text p {
    max-width: 560px;
  }
  @media screen and (max-width: 1480px) {
    .ems_list__block {
      margin-left: 75px;
      padding-left: 60px;
    }
  }
  @media screen and (max-width: 1280px) {
    .ems_list__block {
      margin-left: 50px;
      padding-left: 40px;
    }
    .ems_list__sub {
      font-size: 30px;
      min-height: 68px;
    }
  }
  @media screen and (max-width: 1140px) {
    .ems h1 {
      margin-bottom: 40px;
    }
    .ems_paragraph {
      margin-bottom: 30px;
    }
    .ems_list {
      margin-bottom: 30px;
    }
    .ems_dark {
      margin: 55px auto;
      padding-top: 45px;
      padding-bottom: 35px;
    }
    .ems_list__block {
      margin-left: 30px;
      padding: 20px 5px 20px 30px;
    }
    .ems_list__sub {
      font-size: 26px;
      min-height: 60px;
    }
  }
  @media screen and (max-width: 768px) {
    .ems_list li {
      width: 100%;
      max-width: initial;
      margin-bottom: 20px;
    }
    .ems_list li img,
    .ems_row img {
      margin: 0 auto;
    }
    .ems_list__sub {
      min-height: initial;
    }
    .ems_row__img {
      width: 100%;
    }
    .ems_list__block,
    .ems_row__text {
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      padding: 25px 5px 10px 20px;
    }
  }
  @media screen and (max-width: 600px) {
    .ems_list__sub {
      font-size: 22px;
      margin-bottom: 15px;
    }
  }
  @media screen and (max-width: 480px) {
    .ems_list__sub {
      font-size: 20px;
    }
  }