/* Страница Косметология */
.cosmetology {
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 100px;
}
.cosmetology h1 {
  margin-bottom: 95px;
}
.cosmetology_intro {
  position: relative;
  padding-top: 50px;
  margin-bottom: 95px;
}
.cosmetology_intro::before {
  content: "";
  background-color: var(--lightgray);
  height: 70%;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translateX(-35%);
  width: 100%;
  z-index: -1;
}
.cosmetology_intro__img {
  position: relative;
  width: 36%;
  padding-top: 5px;
  z-index: 2;
}
.cosmetology_intro__img img {
  margin: 0 auto;
}
.cosmetology_intro__text {
  width: 59.5%;
  & h2 {
    text-align: left;
  }
}
.cosmetology_intro__text p {
  max-width: 780px;
  line-height: 1.55em;
  margin-bottom: 12px;

  &:first-of-type {
    position: relative;
    padding-top: 40px;
    
    &::before {
      content: "";
      position: absolute;
      width: 50px;
      height: 5px;
      left: 0;
      top: 0;
      background-color: var(--red);
    }
    @media screen and (max-width: 1140px) {
      padding-top: 25px;
    }
  }
}
.cosmetology_intro__text p + p {
  margin-top: 30px;
}
.cosmetology_intro__billet {
  padding: 50px 40px 20px 160px;
}
.cosmetology_intro__billet:before {
  background-color: var(--dark);
  content: "";
  height: 100%;
  left: 5px;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  width: 25%;
}
.cosmetology_intro__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 650px;
}
.cosmetology_intro__list li {
  position: relative;
  padding-top: 13px;
  margin-bottom: 25px;
  width: 48%;
  max-width: 260px;
  line-height: 1.55em;
}
.cosmetology_intro__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 5px;
  background: var(--red);
}
.cosmetology_lifting {
  position: relative;
  margin-bottom: 100px;
}
.cosmetology_lifting__row {
  align-items: center;
}
.cosmetology_lifting__text {
  position: relative;
  padding: 55px 0 35px;
  width: 52%;
}
.cosmetology_lifting__text::before {
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 200vw;
  height: 100%;
  background-color: var(--lightgray);
}
.cosmetology_lifting__text p {
  max-width: 620px;
  line-height: 1.55em;
}
.cosmetology_lifting__list {
  display: grid;
  grid-template-columns: 40% 55%;
  justify-content: space-between;
  align-items: start;
  padding: 20px 0 0 30px;
  max-width: 600px;
  margin: 0 auto;
}
.cosmetology_lifting__list li {
  position: relative;
  padding-bottom: 12px;
  margin-bottom: 20px;
  font-weight: 600;
}
.cosmetology_lifting__list li::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50px;
  height: 5px;
  background-color: var(--red);
}
.cosmetology_lifting__img {
  position: relative;
  width: 45%;
}
.cosmetology_lifting__img img {
  margin: 0 auto;  
}
.cosmetology_injection__wrap h2 {
  text-align: left;
  margin-bottom: 65px;
}
.cosmetology_injection {
  background: linear-gradient(to bottom,transparent 0 112px,var(--lightgray) 112px 100%);
  padding-bottom: 25px;
  margin-bottom: 95px;
}
.cosmetology_injection__text {
  width: 50%;
}
.cosmetology_injection__billet {
  padding: 50px 40px 30px 100px;
  margin-bottom: 60px;
}
.cosmetology_injection__billet:before {
  background-color: var(--dark);
  content: "";
  height: 100%;
  position: absolute;
  right: 1px;
  top: 0;
  transform: translateX(100%);
  width: 31%;
}
.cosmetology_injection__text p {
  max-width: 700px;
  margin-bottom: 30px;
}
.cosmetology_injection__img {
  position: relative;
  width: 45%;
  margin-top: 112px;
}
.cosmetology_injection__img img {
  margin-left: auto;
}
.cosmetology_botox {
  margin-bottom: 180px;
}
.cosmetology_botox h2 {
  text-align: left;
}
.cosmetology_botox__list {
  display: grid;
  grid-template-columns: repeat(4, 22%);
  justify-content: space-between;
  row-gap: 30px;
  padding-top: 30px;
  margin-bottom: 40px;
}
.cosmetology_botox__list li {
  position: relative;
}
.cosmetology_botox__list li::before {
  position: absolute;
  display: block;
  z-index: -1;
  width: 104%;
  height: 86%;
  background-color: var(--lightgray);
  left: -8%;
  bottom: 0;
}
.cosmetology_botox__list li:nth-child(even) p {
  padding-right: 15px;
}
.cosmetology_botox__list li:nth-child(even)::before {
  content: "";
}
.cosmetology_botox__list li img {
  display: block;
  margin-bottom: 30px;
}
.cosmetology_outro {
  position: relative;
  margin-bottom: 100px;
}
.cosmetology_outro::before {
  content: "";
  background-color: var(--lightgray);
  height: 84%;
  left: 50%;
  position: absolute;
  top: 12%;
  transform: translateX(-35%);
  width: 100%;
  z-index: -1;
}
.cosmetology_outro__img {
  width: 37%;
  padding-top: 40px;
}
.cosmetology_outro__img img {
  margin: 0 auto;
}
.cosmetology_outro__text {
  width: 55.5%;
}
.cosmetology_outro__text p {
  line-height: 1.55em;
  padding-left: 100px;
  margin-bottom: 25px;
}
.cosmetology_outro__billet {
  padding: 40px 40px 20px 100px;
  margin-bottom: 40px;
}
.cosmetology_outro__billet::before {
  background-color: var(--dark);
  content: "";
  height: 100%;
  right: 5px;
  position: absolute;
  top: 0;
  transform: translateX(100%);
  width: 50vw;
}
.cosmetology_outro .cosmetology_outro__billet p {
  padding-left: 0;
  max-width: 580px;
}
@media screen and (min-width: 1800px) {
  .cosmetology_intro:before,
  .cosmetology_intro:before {
    transform: translateX(-500px);
  }
}

@media screen and (max-width: 1400px) {
  .cosmetology h1 {
    margin-bottom: 45px;
  }
  .cosmetology_intro__billet {
    padding: 35px 40px 20px 80px;
  }
  .cosmetology_injection__billet {
    padding: 40px 40px 20px 50px;
    margin-bottom: 35px;
  }
  .cosmetology_botox {
    margin-bottom: 100px;
  }
  .cosmetology_outro__billet {
    padding: 30px 40px 20px 40px;
  }  
  .cosmetology_outro__text p {
    padding-left: 40px;
  }
}
@media screen and (max-width: 1200px) {
  .cosmetology {
    padding-bottom: 60px;
  }
  .cosmetology_intro,
  .cosmetology_lifting,
  .cosmetology_injection,
  .cosmetology_botox {
    margin-bottom: 60px;
  }
  .cosmetology_injection__wrap h2 {
    margin-bottom: 35px;
  }
  .cosmetology_intro__billet {
    padding: 30px 30px 20px 0px;
  }
  .cosmetology_lifting__list {
    padding: 0;
  }
  .cosmetology_botox__list {
    grid-template-columns: repeat(4, 23%);
  }
  .cosmetology_botox__list li::before {
    width: 100%;
    left: -5%;
  }
  .cosmetology_botox__list li:nth-child(even) p {
    padding-right: 5%;
  }
  .cosmetology_outro {
    margin-bottom: 60px;
  }
  .cosmetology_outro::before {
    height: 90%;
  }
  .cosmetology_injection {
    background: linear-gradient(to bottom,transparent 0 80px,var(--lightgray) 80px 100%);
  }
  .cosmetology_injection__img {
    margin-top: 80px;
  }
}
@media screen and (max-width: 992px) {
  .cosmetology h1 {
    margin-bottom: 35px;
  }
  .cosmetology_intro {
    padding-top: 30px;
  }
  .cosmetology_intro__billet {
    padding: 25px 20px 15px 0px;
  }
  .cosmetology_intro__img {
    width: 38%;
  }
  .cosmetology_botox__list {
    grid-template-columns: repeat(2, 47%);
    padding-top: 10px;
  }
  .cosmetology_intro__text p + p {
    margin-top: 20px;
  }
  .cosmetology_intro__list li {
    margin-bottom: 15px;
  }
  .cosmetology_injection__img {
    width: 100%;
    order: -1;
    margin-top: 0px;
  }
  .cosmetology_injection__img img {
    margin: 0 auto 20px;
  }
  .cosmetology_injection__text {
    width: 100%;
  }
  .cosmetology_outro__img {
    width: 40%;
  }
  .cosmetology_outro__text {
    width: 56%;    
  }
  .cosmetology_outro__text p {
    padding-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .cosmetology_intro {
    padding-top: 0;
  }
  .cosmetology_intro__img {
    width: 100%;
    padding: 0;
    margin-bottom: 30px;
  }
  .cosmetology_intro__text{
    width: 100%;
  }
  .cosmetology_intro::before,
  .cosmetology_outro::before {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 80%;
    top: auto;
    transform: none;
  }
  .cosmetology_lifting__text {
    position: static;
    width: 100%;
    padding: 30px 0 25px;
  }
  .cosmetology_lifting__text::before {
    height: 80%;
  }
  .cosmetology_lifting__img {
    width: 100%;
    order: -1;
  }
  .cosmetology_outro__img {
    width: 100%;
  }
  .cosmetology_outro__text {
    width: 100%;    
  }
  .cosmetology_botox__list li img {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 600px) {
  .cosmetology_intro__list li {
    width: 100%;
    max-width: initial;
    padding-top: 10px;
  }
  .cosmetology_lifting__list {
    grid-template-columns: 100%;
  }
  .cosmetology_lifting__list li {
    margin-bottom: 15px;
  }
  .cosmetology_injection__billet,
  .cosmetology_outro__billet {
    padding: 30px 20px 10px 30px;
    margin-bottom: 25px;
  }
  .cosmetology_injection__text p {
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .cosmetology_botox {
    margin-bottom: 40px;
  }
  .cosmetology_botox__list {
    grid-template-columns: 100%;
    max-width: 340px;
    margin: 0 auto;
    row-gap: 20px;
    padding-top: 0px;
  }
}
/* end Страница Косметология */