/* Страница Японский массаж (Кобидо) */
.kobido {
    overflow: hidden;
    padding: 25px 0;
  }
  .kobido_intro {
    margin-bottom: 95px;
  }
  .kobido_intro__row {
    padding-top: 65px;
  }
  .kobido_intro__img {
    width: 36%;
    padding-top: 40px;
    padding-top: 55px;
  }
  .kobido_intro__img img {
    display: block;
    margin: 0 auto;
  }
  .kobido_intro__text {
    width: 60%;
    max-width: 910px;
  }
  .kobido_intro__block {
    position: relative;
    padding-top: 50px;
    margin-bottom: 40px;

    p:first-child {
      position: relative;
      padding-top: 40px;

      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        left: 0;
        top: 0;
        background-color: var(--red);
      }
      @media screen and (max-width: 1140px) {
        padding-top: 25px;
      }
    }
  }
  .kobido_intro__block:before,
  .kobido_precautions__block::before {
    background: var(--lightgray);  
    content: "";
    display: block;  
    position: absolute;
    left: -30%;
    width: 100vw;
    z-index: -1;
  }
  .kobido_intro__block:before {
    top: 0;
    height: 200%;
  }
  .kobido_intro__block p {
    line-height: 1.55;
    max-width: 780px;
  }
  .kobido_intro__billet {
    padding: 50px 20px 55px 70px;
  }
  .kobido_intro__billet::before {
    background-color: var(--dark);
    content: "";
    display: block;
    height: 100%;
    left: 5px;
    transform: translateX(-100%);
    position: absolute;
    top: 0;
    width: 50%;
    z-index: -1;
  }
  .kobido_intro__billet h2 {
    text-align: left;
    margin: 0 0 45px;
  }
  .kobido_intro__billet p {
    line-height: 1.55;
    max-width: 750px;

    &:first-of-type {
      position: relative;
      padding-top: 40px;

      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        left: 0;
        top: 0;
        background-color: var(--red);
      }
      @media screen and (max-width: 1140px) {
        padding-top: 25px;
      }
    }
  }
  .kobido_prepare {
    background: linear-gradient(to bottom, transparent 0 90px, var(--lightgray) 90px 100%);
    padding-bottom: 30px;
    margin-bottom: 150px;
  }
  .kobido_prepare__img {
    width: 37%;
  }
  .kobido_prepare__img img {
    display: block;
    margin: 0 auto;
  }
  .kobido_prepare__text {
    width: 60%;
    margin-top: 90px;
    padding-top: 50px;
  }
  .kobido_prepare__text h2 {
    text-align: left;
    margin: 0 0 40px;
  }
  .kobido_prepare__list {
    max-width: 780px;
    padding: 20px 0 0 20px;
    margin-bottom: 50px;
  }
  .kobido_prepare__list li {
    position: relative;
    padding-top: 15px;
    line-height: 1.6em;
    margin-bottom: 30px;
  }
  .kobido_prepare__list li::before,
  .kobido_precautions__list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 50px;
    height: 5px;
    background-color: var(--red);
  }
  .kobido_precautions {
    margin-bottom: 120px;
  }
  .kobido_precautions__container {
    max-width: 1590px;
  }
  .kobido_precautions__row {
    padding-top: 40px;
  }
  .kobido_precautions h2 {
    text-align: left;
  }
  .kobido_precautions__img {
    width: 38%;
    padding-top: 40px;
  }
  .kobido_precautions__text {
    width: 58%;
    max-width: 890px;
  }
  .kobido_precautions__block {
    position: relative;
    padding: 30px 20px 5px 100px;
  }
  .kobido_precautions__block::before {
    height: 136%;
    bottom: 0;
  }
  .kobido_precautions__list {
    padding: 5px 0 0 25px;
  }
  .kobido_precautions__list li {
    position: relative;
    padding-top: 15px;
    margin-bottom: 30px;
    line-height: 1.55em;
  }
  .kobido_precautions__billet {
    padding: 45px 20px 20px 100px;

    p:first-child {
      position: relative;
      padding-top: 40px;
      
      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        left: 0;
        top: 0;
        background-color: var(--red);
      }
      @media screen and (max-width: 1140px) {
        padding-top: 25px;
      }
    }
  }
  .kobido_precautions__billet::before {
    background-color: var(--dark);
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: calc(100% - 5px);
    width: 50vw;
    height: 100%;
  }
  .kobido_precautions__billet p {
    max-width: 745px;
  }
  .kobido_effects {
    margin-bottom: 115px;
  }
  .kobido_effects_sub {
    text-align: center;
  }
  .kobido_effects__list {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    justify-content: space-between;
    column-gap: 10px;
    row-gap: 30px;
    padding-top: 22px;
    margin: 0 0 40px;
  }
  .kobido_effects__list li {
    position: relative;
    text-align: center;
    min-width: 100px;
    padding-top: 105px;
    line-height: 1.6em;
  }
  .kobido_effects__list li::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 94px;
    height: 94px;
    border-radius: 50%;
    border: 3px solid #252525;
  }
  .kobido_effects__list li:nth-child(1):before {
    background: url("/assets/img/kobido_effects_01.svg") center center / 80% no-repeat;
  }
  .kobido_effects__list li:nth-child(2):before {
    background: url("/assets/img/kobido_effects_02.svg") center center / 70% no-repeat;
  }
  .kobido_effects__list li:nth-child(3):before {
    background: url("/assets/img/kobido_effects_03.svg") center center / 70% auto no-repeat;
  }
  .kobido_effects__list li:nth-child(4):before {
    background: url("/assets/img/kobido_effects_04.svg") center center / 75% auto no-repeat;
  }
  .kobido_effects__list li:nth-child(5):before {
    background: url("/assets/img/kobido_effects_05.png") center center / 65% auto no-repeat;
  }
  .kobido_effects__list li:nth-child(6):before {
    background: url("/assets/img/kobido_effects_06.svg") center center / 65% auto no-repeat;
  }
  .kobido_contrains {
    margin-bottom: 80px;
  }
  .kobido_contrains__row {
    align-items: flex-start;
  }
  .kobido_contrains__header {
    width: 58%;
    position: relative;
    background-color: var(--lightgray);
    padding-top: 60px;
    padding-right: 20px;
  }
  .kobido_contrains__header::before {
    content: "";
    position: absolute;
    display: block;
    width: 100vw;
    height: 190%;
    z-index: -1;
    background-color: var(--lightgray);
    top: 0;
    right: 0;
  }
  .kobido_contrains__header h2 {
    text-align: left;
    margin: 0 0 30px;
  }
  .kobido_contrains__text {
    width: 43%;
    padding-right: 30px;
  }
  .kobido_contrains__billet {
    position: relative;
    width: 57%;
    padding: 45px 0 45px 40px;
    margin-top: 5px;
    z-index: 1;
  }
  .kobido_contrains__billet::before {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    right: 5px;
    transform: translateX(100%);
    background-color: var(--dark);
    width: 100vw;
    height: 100%;
  }
  .kobido_contrains__list {
    display: grid;
    grid-template-columns: 28% 44% 24%;
    justify-content: space-between;
  }
  .kobido_contrains__list li {
    position: relative;
    padding-top: 15px;
    margin-bottom: 12px;
    font-weight: normal;
    line-height: 1.55em;
  }
  .kobido_contrains__list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 5px;
    background-color: var(--red);
  }
  @media screen and (min-width: 1800px) {
    .kobido_intro__block:before {
      left: -350px;
    }
    .kobido_contrains__text::before {
      right: -85px;
    }
    .kobido_effects__list {
      column-gap: 40px;
    }
  }
  @media screen and (max-width: 1400px) {
    .kobido_intro {
      margin-bottom: 75px;
    }
    .kobido_intro__row,
    .kobido_precautions__row {
      padding-top: 20px;
    }
    .kobido_intro__billet {
      padding: 40px 20px 40px 35px;
    }
    .kobido_prepare {
      margin-bottom: 100px;
    }
    .kobido_precautions,
    .kobido_effects {
      margin-bottom: 80px;
    }
    .kobido_precautions__billet {
      padding: 40px 20px 20px 50px;
    }
    .kobido_precautions__block {
      padding-left: 50px;
    }
    .kobido_contrains__header {
      padding-top: 40px;
    }
    .kobido_contrains__header h2 {
      margin-bottom: 20px;
    }
    .kobido_contrains__billet {
      padding: 35px 0 35px 30px;
      margin-top: 0px;
    }
  }
  @media screen and (max-width: 1280px) {
    .kobido_intro__row,
    .kobido_precautions__row {
      padding-top: 0px;
    }
    .kobido_precautions__list {
      padding-left: 15px;
    }
    .kobido_intro__billet {
      padding: 30px 15px 30px 25px;
    }
    .kobido_intro__billet h2,
    .kobido_prepare__text h2 {
      margin-bottom: 25px;
    }
    .kobido_prepare {
      margin-bottom: 75px;
    }  
    .kobido_precautions__billet {
      padding: 30px 15px 15px 30px;
    }
    .kobido_precautions__block {
      padding-left: 30px;
    }
    .kobido_contrains__header {
      padding-top: 30px;
    }
    .kobido_contrains__billet {
      padding: 25px 0 25px 20px;
    }
    .kobido_contrains__list {
      grid-template-columns: repeat(2, 48%);
    }
    .kobido_contrains__list > ul:nth-child(2) {
      display: grid;
      grid-template-columns: repeat(2, 48%);
      justify-content: space-between;
      grid-column: 1/-1;
      order: 1;
    }
    .kobido_prepare__list li,
    .kobido_precautions__list li {
      margin-bottom: 20px;
    }
    .kobido_prepare__list {
      padding: 10px 0 0 15px;
      margin-bottom: 30px;
    }
    .kobido_contrains {
      margin-bottom: 40px;
    }
  }
  @media screen and (max-width: 992px) {
    .kobido_intro__img,
    .kobido_prepare__img,
    .kobido_precautions__img {
      width: 100%;
      padding-top: 0;
      order: -1;
    }
    .kobido_precautions__img img {
      margin: 0 auto;
    }
    .kobido_intro__text,
    .kobido_prepare__text,
    .kobido_precautions__text {
      width: 100%;
    }
    .kobido_prepare__list li,
    .kobido_precautions__list li {
      padding-top: 10px;
    }  
    .kobido_effects__list {
      grid-template-columns: repeat(3, 31.5%);
      justify-content: space-between;
      column-gap: initial;
      row-gap: 20px;
      margin: 0 0 20px;
    }
    .kobido_intro__block:before, 
    .kobido_precautions__block::before,
    .kobido_contrains__header::before {
      width: 150vw;
      left: 50%;
      transform: translateX(-50%);
    }
    .kobido_prepare__text {
      margin-top: 0;
    }
    .kobido_contrains__text {
      width: 100%;
      padding-right: 0;
    }
    .kobido_contrains__billet {
      width: 100%;
    }
  }
  @media screen and (max-width: 768px) {
    .kobido_precautions,
    .kobido_effects {
      margin-bottom: 60px;
    }
  }
  @media screen and (max-width: 600px) {
    .kobido_intro__billet,
    .kobido_precautions__block,
    .kobido_precautions__billet,
    .kobido_contrains__billet {
      padding: 30px 0;
    }
    .kobido_intro__billet::before,
    .kobido_precautions__billet::before,
    .kobido_contrains__billet::before {
      width: 150vw;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  @media screen and (max-width: 480px) {
    .kobido_effects__list {
      grid-template-columns: repeat(2, 48.5%);
      padding-top: 5px;
    }
    .kobido_effects__list li {
      line-height: 1.2em;
    }
    .kobido_contrains__list {
      grid-template-columns: 100%;
    }
    .kobido_contrains__list > ul:nth-child(2) {
      display: block;
      order: initial;
    }
  }
  /* end Страница Японский массаж (Кобидо) */