@import "normalize.scss";

@font-face {
  font-family: "Onest";
  src: url("../fonts/Onest-Light.woff2") format("woff2"),
    url("../fonts/Onest-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Onest";
  src: url("../fonts/Onest-Medium.woff2") format("woff2"),
    url("../fonts/Onest-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Onest";
  src: url("../fonts/Onest-SemiBold.woff2") format("woff2"),
    url("../fonts/Onest-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Onest";
  src: url("../fonts/Onest-Bold.woff2") format("woff2"),
    url("../fonts/Onest-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Onest";
  src: url("../fonts/Onest-Regular.woff2") format("woff2"),
    url("../fonts/Onest-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Prata";
  src: url("../fonts/Prata-Regular.woff2") format("woff2"),
    url("../fonts/Prata-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
:root {
  --white: #ffffff;
  --lightgray: #e1e6ec;
  --darkgray: #959595;
  --red: #d71b00;
  --beige: #a7967d;
  --dark: #191919;
  --font-Onest: "Onest", sans-serif;
  --font-Prata: "Prata", serif;
}
* {
  box-sizing: border-box;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style: none;
}
img {
  max-width: 100%;
  height: auto;
}
a {
  color: inherit;
  text-decoration: none;
}
p {
  margin: 0 0 20px;
  padding: 0;
  line-height: 1.5;
}
[data-href] {
  cursor: pointer;
}
body {
  font-size: 18px;
  font-family: var(--font-Onest);
  background-color: #fff;
}
/* @media screen and (max-width: 480px) {
  body {
    font-size: 16px;
  }
} */
h1,
h2,
h3,
.h2 {
  font-family: var(--font-Prata);
  font-weight: normal;
  text-align: center;
}
h1,
h2,
.h2 {
  font-size: 45px;
  margin: 30px 0;
}
@media screen and (max-width: 1280px) {
  h1,
  h2,
  .h2 {
    font-size: 40px;
  }
}
@media screen and (max-width: 992px) {
  h1,
  h2,
  .h2 {
    font-size: 36px;
  }
}
@media screen and (max-width: 768px) {
  h1,
  h2,
  .h2 {
    font-size: 32px;
    margin: 25px 0;
  }
}
@media screen and (max-width: 520px) {
  h1,
  h2,
  .h2 {
    font-size: 30px;
    margin: 20px 0 30px;
  }
}
.container {
  width: 100%;
  max-width: 1560px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 0 auto;
}
.page-wrap {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}
main {
  flex: 1;
}

/* header */
header {
  background: url("/assets/img/header.jpg") center bottom / auto no-repeat,
    #1b1c1d;
  color: var(--white);
}
header.fixheader {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 40;
}
.header {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: flex-end;
  column-gap: 100px;
  row-gap: 15px;
  padding-top: 15px;
}
.header_logo {
  grid-row: 1/3;
  margin-bottom: 14px;
}
.header_logo img {
  display: block;
}
.slogan {
  color: var(--darkgray);
  font-weight: 300;
  letter-spacing: 0.06em;
}
.header_contacts {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  column-gap: 50px;
}
.header_menu {
  grid-column: 2/4;
}
.menu {
  display: flex;
  justify-content: space-between;
  column-gap: 10px;
}
.menu > li > a {
  display: block;
  text-transform: uppercase;
  font-weight: 300;
  letter-spacing: 0.14em;
}
.header_menu .menu > li > a {
  position: relative;
  padding: 15px 0 25px;
  text-align: center;
}
.header_menu .menu > li > a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 3px;
  background: transparent;
  transition: 0.2s;
}
.header_menu .menu > li > a:hover::before,
.header_menu .menu > li > a.active::before {
  background: var(--red);
}
.header_buttons {
  grid-row: 1/3;
  grid-column: 4/-1;
  margin-bottom: 20px;
  padding-left: 50px;
}
.header_socials {
  display: flex;
  column-gap: 25px;
}
header .entry-btn {
  min-height: 50px;
  width: 215px;
  max-width: 100%;
  margin-bottom: 30px;
  transition: none;
}
.header_menu .entry-btn {
  display: none;
}
.menu-toggle {
  display: none;
  align-items: center;
  padding: 0;
  border: none;
  background: transparent;
  width: 33px;
  height: 30px;
  font-size: 0px;
  cursor: pointer;
}
.menu-toggle span {
  position: relative;
  width: 100%;
  height: 4px;
  background-color: var(--white);

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    left: 0;
  }
  &::before {
    top: -12px;
    transition: top 0.3s;
  }
  &::after {
    bottom: -12px;
    transition: bottom 0.3s;
  }
}
.header.is-active .menu-toggle {
  position: absolute;
  top: 25px;
  right: 15px;
}
.header.is-active .menu-toggle span {
  background-color: transparent;

  &::before {
    top: 0;
    transform: rotate(-45deg);
    transition: transform 0.3s;
  }
  &::after {
    bottom: 0;
    transform: rotate(45deg);
    transition: transform 0.3s;
  }
}

@media screen and (max-width: 1480px) {
  .header {
    column-gap: 75px;
  }
  .header_buttons {
    padding-left: 0;
  }
}
@media screen and (max-width: 1280px) {
  .header {
    column-gap: 40px;
  }
  .header_contacts {
    column-gap: 15px;
  }
}
@media screen and (max-width: 1140px) {
  .header {
    column-gap: 30px;
  }
  .header_logo {
    max-width: 100px;
  }
  header .entry-btn {
    width: 190px;
    margin-bottom: 15px;
  }
  .header_menu {
    font-size: 17px;
  }
}
@media screen and (max-width: 992px) {
  .header {
    column-gap: 20px;
    row-gap: 10px;
    grid-template-columns: auto 1fr auto;
    align-items: center;
  }
  .header_logo {
    margin-bottom: 5px;
  }
  .header_slogan {
    display: none;
  }
  .header_menu {
    font-size: 16px;
    grid-column: 2/-1;
  }
  .header_buttons {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    row-gap: 10px;
    grid-column: 3/-1;
    grid-row: 1/2;
    margin: 0;
  }
  header .entry-btn {
    width: 180px;
    margin: 0;
    min-height: 45px;
  }
  .header_contacts {
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    row-gap: 5px;
  }
  .header_socials {
    column-gap: 10px;
  }
}
@media screen and (max-width: 768px) {
  header {
    background: #1a1a1a;
  }
  header.fixheader {
    position: static;
    background: #1a1a1a;
  }
  .header {
    grid-template-columns: 1fr auto auto;
    row-gap: 0;
    padding-bottom: 5px;
  }
  .header_contacts {
    flex-direction: row;
    grid-column: 2/-1;
  }
  .header_menu {
    grid-column: 1/-1;
    order: 2;
  }
  .header_buttons {
    grid-row: 2/3;
    grid-column: 2/3;
  }
  .header_menu {
    display: none;
  }
  .menu-toggle {
    display: flex;
  }
  .header.is-active {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    padding: 0 0 35px;
    background: url("/assets/img/mobile-header_bg.jpg") center center / cover
      no-repeat;

    & .header_logo {
      width: 100%;
      max-width: initial;
      background-color: #1a1a1b;
      padding: 5px 5px 5px 15px;
      order: -2;
    }
    & .header_logo img {
      width: 54px;
    }
    & .header_menu {
      display: block;
      font-size: 18px;
      order: -1;
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;
    }
    & .header_menu .menu {
      position: relative;
      display: block;
      text-align: center;
      padding-bottom: 20px;
      margin-bottom: 40px;
    }
    & .header_menu .menu::before {
      content: "";
      position: absolute;
      width: 100%;
      max-width: 363px;
      height: 3px;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      background: url("/assets/img/header_menu_decor.png") center center / 100%
        auto no-repeat;
    }
    & .header_menu .menu > li > a {
      padding: 12px 0;
    }
    & .header_menu .menu > li > a::before {
      display: none;
    }
    & .header_contacts {
      flex-direction: column;
      align-items: center;
      font-size: 18px;
      margin-bottom: 25px;
    }
    & .header_contacts .contact-elem {
      display: flex;
      font-size: inherit;
    }
    & .header_menu .entry-btn {
      display: flex;
      width: 100%;
      max-width: 230px;
      min-height: 52px;
      margin: 0 auto;
    }
    & .header_buttons .entry-btn {
      display: none;
    }
    & .header_socials {
      column-gap: 30px;
    }
    & .messenger {
      width: 58px;
      height: 58px;
    }
    & .messenger.whatsapp img {
      width: 58px;
    }
    & .header_buttons {
      display: flex;
    }
  }
}
@media screen and (max-width: 600px) {
  .header_contacts {
    flex-direction: column;
  }
  .header_menu .menu {
    display: block;
  }
  .header_menu .menu > li > a {
    text-align: center;
    padding: 10px 0;
  }
}
@media screen and (max-width: 500px) {
  .header {
    grid-template-columns: 1fr auto auto;
    row-gap: 10px;
    padding-top: 5px;
  }
  .header_logo {
    grid-row: initial;
    max-width: 54px;
    margin: 0;
  }
  .header_buttons {
    display: none;
  }
  .header_contacts {
    align-items: center;
    grid-column: initial;
  }
  .header .contact-elem--phone {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .header .contact-elem--adr {
    display: none;
  }
  .header_buttons {
    justify-content: center;
  }
}
/* end header */

/* fixed */
header.fixed {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 40;
  background: url("/assets/img/fixed_bg.jpg") center center / cover no-repeat, #1a1a1a;
}
.fixed .header {
  grid-template-columns: auto  1fr auto auto;
  grid-template-rows: 1fr auto;
  column-gap: 25px;
  padding-top: 10px;
}
.fixed .header_logo {
  margin-bottom: 10px;
  margin-right: 15px;
}
.fixed .header_logo img {
  width: 80px;
}
.fixed .header_menu {
  grid-column: 2/3;
  grid-row: 2/3;
  max-width: 740px;
}
.fixed .header_menu .menu > li > a {
  padding-top: 5px;
  padding-bottom: 30px;
}
.fixed .header_slogan {
  grid-row: 1/2;
  grid-column: 2/4;
}
.fixed .header_contacts {
  grid-row: 1/3;
  grid-column: 3/4;
  align-self: center;
  display: flex;
  align-items: flex-end;
  min-height: 50px;
}
.fixed .header_buttons {
  grid-row: 1/3;
  grid-column: initial;
  display: flex;
  align-items: center;
  align-self: center;
  column-gap: 25px;
  padding-left: 0;
  margin-bottom: 0;
}
.fixed  .entry-btn {
  order: 1;
  margin: 0;
}
.fixed .messenger.whatsapp,
.fixed .contact-elem--adr {
  display: none;
}
@media screen and (max-width: 1280px) {
  .fixed .header {
    column-gap: 20px;
  }
  .fixed .header_slogan {
    grid-column: 2/3;
  }
  .fixed .header_contacts {
    grid-row: 1/2;
  }
  .fixed .header_menu {
    grid-column: 2 / 4;
    max-width: initial;
  }
  .fixed .header_logo {
    margin-right: 0;
  }
  .fixed .header_buttons,
  .fixed .header_socials {
    column-gap: 20px;
  }
}
@media screen and (max-width: 1140px) {
  .fixed .header_buttons {
    flex-direction: column;
    row-gap: 10px;
    align-self: start;
  }
  .fixed  .entry-btn {
    order: initial;
    width: 170px;
    min-height: 45px;
  }
}
@media screen and (max-width: 890px) {  
  .fixed .header_menu {
    grid-column: 2/-1;
  }
  .fixed .header_contacts {
    grid-column: 2/3;
  }
  .fixed .header_buttons {
    grid-column: 3/-1;
    grid-row: 1/2;
    flex-direction: row;
    align-self: center;
  }
}
@media screen and (max-width: 768px) {
  .fixed .header {
    column-gap: 15px;
    padding-top: 0px;
    grid-template-rows: initial;
  }
  .fixed .header_buttons {
    grid-column: 3/4;
    column-gap: 15px;
  }
  .fixed .header_logo {
    padding-top: 5px;
    margin-bottom: 5px;
    grid-row: initial;
  }
  .fixed .header_logo img {
    width: 54px;
  }
  .fixed .header_contacts {
    min-height: 45px;
    justify-content: flex-end;
  }
  .fixed .header_socials {
    column-gap: 15px;
  }
}
@media screen and (max-width: 600px) {
  .fixed .header,
  .fixed .header_buttons,
  .fixed .header_socials {
    column-gap: 10px;
  }
  .fixed .entry-btn {
    width: 100px;
    border-radius: 10px;
  }
}
@media screen and (max-width: 500px) {
  .fixed .header {
    grid-template-columns: auto 1fr auto;
    column-gap: 20px;
  }
  .fixed .header_buttons {
    display: none;
  }
  .fixed .header_contacts {
    min-height: initial;
    justify-content: initial;
  }
  .fixed .header_logo {
    margin-bottom: 0px;
  }
}
/* end fixed */

/* footer */
footer {
  background-color: var(--dark);
  color: var(--white);
}
.footer {
  display: grid;
  grid-template-columns: auto auto 1fr auto;
  align-items: center;
  column-gap: 100px;
  row-gap: 15px;
  padding-top: 15px;
  padding-top: 40px;
  padding-bottom: 20px;
}
.footer_logo {
  grid-row: 1/3;
  margin-bottom: 14px;
}
.footer_logo img {
  display: block;
}
.footer_menu {
  grid-column: 2/4;
  padding-top: 10px;
}
.footer_menu .menu > li > a {
  position: relative;
  padding: 10px 0;
}
.footer_contacts {
  grid-column: 2/4;
  display: grid;
  grid-template-columns: repeat(4, auto);
  align-items: center;
  column-gap: 45px;
  margin-bottom: 15px;
}
.footer_socials {
  display: flex;
  column-gap: 30px;
}
.footer_socials .messenger {
  width: 58px;
  height: 58px;
}
.footer_slogan {
  font-size: 16px;
  letter-spacing: 0.14em;
}
.copy {
  color: var(--darkgray);
  font-size: 13px;
  font-weight: 300;
  margin-left: 50px;
}
.copy span {
  display: block;
}
.copy span + span {
  margin-top: 10px;
}
.footer .entry-btn {
  display: none;
  min-height: 50px;
  width: 228px;
  max-width: 100%;
  margin-left: auto;
}
@media screen and (max-width: 1480px) {
  .footer {
    column-gap: 75px;
  }
  .copy {
    margin-left: 0;
  }
  .footer_contacts {
    column-gap: 30px;
  }
}
@media screen and (max-width: 1380px) {
  .footer {
    column-gap: 40px;
  }
  .footer_contacts {
    column-gap: 20px;
  }
  .footer_socials {
    column-gap: 15px;
  }
}
@media screen and (max-width: 1280px) {
  .footer_contacts {
    grid-template-columns: repeat(2, auto);
  }
  .footer_socials {
    grid-column: 2/-1;
    grid-row: 1/3;
  }
}
@media screen and (max-width: 1140px) {
  .footer {
    grid-template-columns: auto 1fr auto;
  }
  .footer_logo {
    grid-row: 1/4;
    max-width: 100px;
  }
  .footer .entry-btn {
    order: 1;
  }
  .copy {
    order: 2;
    grid-column: 2/-1;
    text-align: end;
  }
  .copy span {
    display: inline;
  }
  .copy .footer_creator {
    display: block;
  }
  .footer_contacts {
    margin-bottom: 0;
  }
  .footer_slogan {
    display: none;
  }
}
@media screen and (max-width: 992px) {
  .footer {
    column-gap: 25px;
    padding-top: 30px;
  }
  .footer_menu {
    font-size: 16px;
    padding-top: 0;
  }
  .footer .entry-btn {
    width: 180px;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    column-gap: 20px;
  }
  .footer_menu {
    order: -1;
    grid-column: 1/-1;
  }
  .footer_logo {
    grid-row: 2/4;
    margin: 0;
  }
  .footer_contacts {
    grid-column: 2/-1;
  }
  .copy {
    grid-column: initial;
    text-align: left;
  }
  .copy span {
    display: block;
  }
}
@media screen and (max-width: 600px) {
  footer {
    background-color: #000;
  }
  .footer {
    grid-template-columns: 1fr;
    padding-top: 25px;
    padding-left: 20px;
  }
  .footer .entry-btn {
    display: flex;
    width: 230px;
    margin: 0 0 20px;
  }
  .footer_logo {
    grid-row: initial;
    max-width: 130px;
    margin: 0 0 30px;
  }
  .footer_menu {
    order: initial;
    margin-bottom: 30px;
  }
  .footer_menu .menu {
    display: block;
  }
  .footer_menu .menu > li > a {
    text-transform: none;
    padding: 8px 0;
    font-size: 18px;
  }
  .footer_contacts {
    grid-column: 1/-1;
    justify-content: center;
    display: block;
    font-size: 18px;
  }
  .footer_contacts .contact-elem {
    font-size: inherit;
    margin-bottom: 12px;
  }
  .footer_socials {
    grid-column: initial;
    grid-row: initial;
    margin: 60px 0 45px;
    column-gap: 30px;
  }
  .footer_slogan {
    display: block;
    margin-bottom: 12px;
  }
  .copy {
    grid-column: 1/-1;
    text-align: left;
  }
}
/* end footer */

.entry-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #06bc4f;
  font-size: 15px;
  padding: 5px;
  border-radius: 40px;
  border: none;
  color: var(--white);
  cursor: pointer;
  transition: 0.1s;
}
.entry-btn--beige {
  background-color: var(--beige);
}
.entry-btn--white {
  color: var(--dark);
  background-color: var(--white);
}
.entry-btn--beige:hover {
  background-color: var(--white);
  color: #191a19;
  box-shadow: 0px 2px 8px 0px rgba(43, 43, 43, 0.3);
}

.messenger {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 46px;
  border-radius: 10px;
  flex-shrink: 0;
  overflow: hidden;
}
.messenger img {
  display: block;
}
.messenger.telegram {
  background-color: #5ebee1;
}
.messenger.vk {
  background-color: #ffffff;
}
.gray {
  background-color: var(--lightgray);
}
.contact-elem {
  display: flex;
  align-items: center;
  min-height: 22px;
  line-height: 1;
  position: relative;
  padding-left: 30px;
  font-size: 16px;
}
.contact-elem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  background-position: center;
  background-repeat: no-repeat;
  filter: invert(46%) sepia(1%) saturate(0%) hue-rotate(75deg) brightness(92%)
    contrast(90%);
}
.contact-elem--phone {
  font-size: 26px;
  white-space: nowrap;
}
.contact-elem--phone::before {
  background-image: url("/assets/img/phone_icon.svg");
  background-size: 20px;
}
.contact-elem--adr::before {
  background-image: url("/assets/img/adr_icon.svg");
  background-size: 18px;
}
.contact-elem--time::before {
  background-image: url("/assets/img/time_icon.svg");
  background-size: 22px;
}


.mainslider {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}
.mainslider .swiper-slide {
  height: auto;
}
.mainslider .swiper-pagination {
  display: none;
  position: absolute;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 5px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
  width: 100%;
}
.mainslider .swiper-pagination-bullet {
  cursor: pointer;
  position: relative;
}
.mainslider .slider-prev,
.mainslider .slider-next {
  top: 60%;
}
.mainslider .slider-prev {
  left: 15px;
}
.mainslider .slider-next {
  right: 15px;
}

@media screen and (min-width: 1600px){
  .mainslider .slider-prev {
    left: 50%;
    transform: translate(-760px,-50%) scale(-1, 1);
  }
  .mainslider .slider-next {
    right: 50%;
    transform: translate(760px,-50%);
  }
}
@media screen and (max-width: 992px) {
  .mainslider .slider-prev {
    left: 10px;
  }
  .mainslider .slider-next {
    right: 10px;
  }
}
@media screen and (max-width: 768px) {
  .mainslider .slider-prev,
  .mainslider .slider-next {
    display: none;
  }
  .mainslider .swiper-pagination {
    display: flex;
  }
}
.main-slider_elem {
  min-height: 950px;
  padding-top: 200px;
  height: 100%;
}
.main-slider_elem .container {
  padding-left: 90px;
  padding-right: 90px;
}
.main-slide {
  background: url("/assets/img/main-slide.jpg") center top / auto no-repeat;
  color: #fff;
  text-align: center;
  padding-bottom: 80px;
}
.main-slide_content {  
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}
.main-slide_title {
  font-family: var(--font-Prata);
  position: relative;
  font-size: 50px;
  padding: 0 0 25px;
  margin: 0 0 25px;
  line-height: 1.45em;
}
.main-slide_title h1 {
  font-size: 80px;
  margin: 0 0 15px;
}
.main-slide_title::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  max-width: 876px;
  height: 9px;
  background: url("/assets/img/main-slide_decor.png") center center / 100% no-repeat;
}
.main-slide_desc {
  font-size: 22px;
  margin-bottom: 80px;
}
.main-slide_btns {
  width: 100%;
  max-width: 620px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main-slide_btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 68px;
  border-radius: 10px;
  background-color: var(--beige);
  width: 48%;
  max-width: 260px;
  font-size: 15px;
  margin-bottom: 18px;
}
@media screen and (max-width: 1600px) {
  .main-slide_title {
    font-size: 3.13vw;
  }
  .main-slide_title h1 {
    font-size: 5vw;
  }
}
@media screen and (max-width: 1280px) {
  .main-slider_elem {
    min-height: 840px;
  }
  .main-slide {
    padding-bottom: 20px;
  }
  .main-slide_content {
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
  }
  .main-slide_desc {
    font-size: 20px;
  }
}
@media screen and (max-width: 992px) {
  .main-slider_elem {
    padding-top: 165px;
  }
  .main-slider_elem .container {
    padding-left: 70px;
    padding-right: 70px;
  }
  .main-slide_title::before {
    max-width: 650px;
  }
  .main-slide_desc {
    margin-bottom: 40px;
  }
  .main-slide_btns {
    justify-content: center;
    column-gap: 4%;
  }
  .main-slide_btns a {
    min-height: 54px;
    max-width: 260px;
  }
}
@media screen and (max-width: 768px) {
  .main-slider_elem {
    padding-top: 0;
    padding-bottom: 50px;
    min-height: 738px;
  }
  .main-slider_elem .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .main-slide_title {
    line-height: 1.35em;
    padding-bottom: 30px;
    font-size: 26px;
  }
  .main-slide_title h1 {
    font-size: 40px;
    line-height: 1.1em;
  }
  .main-slide_desc {
    font-size: 18px;
  }
}
@media screen and (max-width: 600px) {
  .main-slide_title h1 {
    font-size: 38px;
  }
  .main-slide_title {
    font-size: 24px;
  }
}
@media screen and (max-width: 480px) {
  .main-slide {
    background-size: cover;
  }
  .main-slide_title h1 {
    font-size: 36px;
  }
  .main-slide_title {
    font-size: 22px;
  }
  .main-slide_title {
    padding-bottom: 10px;
    margin-bottom: 20px;
  }
  .main-slide_desc {
    line-height: 1.5em;
    margin-bottom: 45px;
  }
  .main-slide_btns a {
    width: 100%;
    min-height: 48px;
    margin-bottom: 24px;
    border-radius: 5px;
  }
}

.vacancy-slide {
  padding: 90px 0 0px;
  color: #fff;
}
.vacancy-slide .container {
  max-width: 1745px;
}
.vacancy-slide_content {
  background: url("/assets/img/main-slide_vacancy.jpg") center 40% / auto no-repeat;
  display: flex;
  min-height: 635px;
  font-size: 20px;
  padding: 40px 50px;
  border-radius: 40px;
}
.vacancy-slide_row {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1330px;
  margin: 0 auto;
}
.vacancy-slide_row::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  width: 5px;
  height: 90%;
  max-height: 597px;
  background: url("/assets/img/vacancy-slide_decor.png") center center / auto 100% no-repeat;
}
.vacancy-slide_title {
  position: relative;
  width: 47%;
}
.vacancy-slide_title::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  max-width: 650px;
  height: 9px;
  background: url("/assets/img/main-slide_decor.png") center center / 100% no-repeat;
  display: none;
}
.vacancy-slide_text {
  width: 47%;
  padding-top: 15px;
  max-width: 570px;
  margin-left: auto;
}
.vacancy-slide h2 {
  font-size: 56px;
  text-align: left;
  line-height: 1.3em;
  margin: 0 0 10px;
}
.vacancy-slide_text p {
  line-height: 1.7em;
  margin-bottom: 0;
}
.vacancy-slide_text p:first-child {
  max-width: 440px;
}
.vacancy-slide_text p + p {
  margin-top: 50px;
}
.vacancy-slide_paragraph {
  font-weight: 600;
}
.vacancy-slide_paragraph a {
  white-space: nowrap;
}
@media screen and (max-width: 1600px) {
  .vacancy-slide_content {
    font-size: 20px;
  }
  .vacancy-slide h2 {
    font-size: 52px;
  }
  .vacancy-slide_text p + p {
    margin-top: 40px;
  }
}
@media screen and (max-width: 1480px) {
  .vacancy-slide h2 {
    font-size: 48px;
  }
  .vacancy-slide_content {
    font-size: 18px;
  }
}
@media screen and (max-width: 1140px) {
  .vacancy-slide h2 {
    font-size: 44px;
  }
  .vacancy-slide_text p + p {
    margin-top: 30px;
  }
}
@media screen and (max-width: 992px) {  
  .vacancy-slide_row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;    
  }
  .vacancy-slide_title {
    width: 100%;
    padding-bottom: 25px;
    margin-bottom: 25px;
  }
  .vacancy-slide_title::before {
    display: block;
  }
  .vacancy-slide_text {
    width: 100%;
    max-width: 620px;
    margin: 0 auto;
    padding-top: 0;
  }
  .vacancy-slide_text p:first-child {
    max-width: initial;
  }
  .vacancy-slide h2 {
    text-align: center;
    margin-bottom: 0;
  }
  .vacancy-slide_row::before {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .vacancy-slide {
    padding-top: 50px;
  }
  .vacancy-slide h2 {
    font-size: 40px;
  }
  .vacancy-slide_content {
    padding: 20px;
    min-height: 630px;
  }
  .vacancy-slide_desktop {
    display: none;
  }
  .vacancy-slide_text p {
    line-height: 1.3em;
  }
  .vacancy-slide_text p + p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 600px) {
  .vacancy-slide h2 {
    font-size: 38px;
  }
  .vacancy-slide_content {
    font-size: 17px;
  }
}
@media screen and (max-width: 480px) {
  .vacancy-slide {
    padding-top: 30px;
  }
  .vacancy-slide_content {
    background: url("/assets/img/main-slide_vacancy-mob.jpg") center top / cover no-repeat;
    border-radius: 35px;
    font-size: 15px;
    padding-top: 0;
    padding-bottom: 40px;
  }
  .vacancy-slide h2 {
    font-size: 36px;
    line-height: 1.3em;
  }
}
@media screen and (max-width: 380px) { 
  .vacancy-slide_content {
    padding-left: 10px;
    padding-right: 10px;
  } 
  .vacancy-slide h2 {
    font-size: 32px;
  }
}


.mainpage_about {
  padding-top: 55px;
  padding-bottom: 75px;
}
.mainpage_about .container {
  max-width: 1745px;
}
.mainpage_about__text {
  background-color: var(--lightgray);
  padding: 90px 45px;
}
.mainpage_about__inner {
  position: relative;
  max-width: 1420px;
  margin: 0 auto;
  padding: 30px 70px 20px;
}
.mainpage_about__inner::before,
.mainpage_about__inner::after {
  position: absolute;
  font-size: 110px;
  color: #cbcfd5;
  font-family: var(--font-Prata);
  line-height: 1em;
}
.mainpage_about__inner::before {
  content: "“";
  left: 0;
  top: 0;
}
.mainpage_about__inner::after {
  content: "”";
  right: 0;
  bottom: 0;
}
.mainpage_about__inner p {
  max-width: 1180px;
  margin: 0 auto 30px;
}
.mainpage_about__slider {
  position: relative;
  padding: 0 15px;
  width: 100%;
  max-width: 1530px;
  margin: -70px auto 0;
}
.slider-prev,
.slider-next {
  position: absolute;
  top: 40%;
  transform: translateY(-50%);
  width: 62px;
  height: 88px;
  background: url("/assets/img/arrow_right.svg") center center / 20px no-repeat,
    rgba(225, 230, 236, 0.9);
  z-index: 5;
  cursor: pointer;
}
.slider-prev {
  left: 0px;
  transform: translateY(-50%) scale(-1, 1);
}
.slider-next {
  right: 0px;
}
.reviews_slider .slider-prev,
.reviews_slider .slider-next {
  top: 45%;
  box-shadow: 0px 3px 5px 1px rgba(122, 122, 122, 0.3);
}
.reviews_slider .slider-prev {
  left: -10px;
}
.reviews_slider .slider-next {
  right: -10px;
}

@media screen and (max-width: 992px) {
  .slider-next, .slider-prev {
    width: 54px;
    height: 76px;
  }
}
@media screen and (max-width: 480px) { 
  .slider-next, .slider-prev {
    width: 40px;
    height: 65px;
    top: 45%;
  }
}
.photo-slider {
  padding-bottom: 70px;
}
.photo-slider-prev,
.photo-slider-next {
  position: absolute;
  top: 34%;
  width: 30px;
  height: 30px;
  background: url("/assets/img/arrow_right.svg") center center / 20px no-repeat;
  z-index: 5;
  cursor: pointer;
}
.photo-slider-prev {
  left: 25px;
  transform: scale(-1, 1);
}
.photo-slider-next {
  right: 25px;
}



.photo-slider .swiper-slide img {
  display: block;
}
.photo-slider .swiper-pagination {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}
.photo-slider .swiper-pagination .swiper-pagination-bullet {
  margin: 0 10px;
}
.swiper-pagination-bullet {
  position: relative;
  display: inline-block;
  width: 49px;
  height: 2px;
  border-radius: 0;
  background: #d3dae3;
  opacity: 1;
}
.swiper-pagination-bullet::before {
  content: "";
  position: absolute;
  left: 0;
  top: -10px;
  width: 100%;
  height: 20px;
}
.swiper-pagination-bullet-active {
  background: #191a19;
}
@media screen and (max-width: 1280px) {
  .mainpage_about__text {
    padding: 50px 30px;
  }
  .mainpage_about__slider {
    margin-top: -40px;
  }
  .mainpage_about__inner {
    padding: 30px 60px 20px;
  }
}
@media screen and (max-width: 1140px) {
  .mainpage_about__slider {
    padding-left: 45px;
    padding-right: 45px;
  }
  .photo-slider {
    padding-bottom: 40px;
  }
  .photo-slider-prev {
    left: 0;
  }
  .photo-slider-next {
    right: 0;
  }
}
@media screen and (max-width: 768px) {  
  .mainpage_about__text {
    padding: 40px 20px;
  }
  .mainpage_about__inner {
    padding: 30px 50px 20px;
  }
  .mainpage_about__slider {
    padding-left: 30px;
    padding-right: 30px;
  }
  .photo-slider-prev {
    left: -5px;
  }
  .photo-slider-next {
    right: -5px;
  }
}
@media screen and (max-width: 600px) {
  .mainpage_about__text {
    padding-right: 10px;
  }
  .mainpage_about__inner {
    padding: 40px 0px 120px;
    font-size: 18px;
  }
  .mainpage_about__inner p {
    line-height: 1.55em;
  }
  .mainpage_about__slider {
    margin-top: -80px;
  }
  .mainpage_about__inner::before {
    top: -20px;
  }
  .mainpage_about__inner::after {
    bottom: 0px;
  }
}
@media screen and (max-width: 480px) {
  .mainpage_about .container {
    padding-left: 0;
    padding-right: 0;
  }
  .mainpage_about__slider {
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;
  }
  .photo-slider {
    min-width: 130%;
  }
  .photo-slider-prev,
  .photo-slider-next {
    display: none;
  }
  .photo-slider .swiper-pagination {
    max-width: calc(100vw - 30px);
    left: 0;
    transform: none;
  }
}
.mainpage_personal {
  overflow: hidden;
  padding-top: 80px;
  padding-bottom: 65px;
}
.mainpage_personal p {
  max-width: 1490px;
  margin: 0 auto 30px;
}
.mainpage_personal__sub {
  text-align: center;
  font-family: var(--font-Prata);
  font-size: 33px;
  padding-top: 5px;
  margin-bottom: 35px;
}
.mainpage_personal__content {
  display: grid;
  grid-template-columns: 27% 1fr;
  column-gap: 85px;
  margin-bottom: 130px;
}
.mainpage_personal__text {
  position: relative;
  background-color: #fff;
  padding: 35px 0 20px 10px;
  font-size: 20px;
}
.mainpage_personal__text::before,
.mainpage_personal__text::after {
  content: "";
  position: absolute;
  background-color: #fff;
}
.mainpage_personal__text::before {
  width: 50px;
  height: 53%;
  left: 0;
  top: 0;
  transform: translateX(-100%);
}
.mainpage_personal__text::after {
  width: 50vw;
  height: 100%;
  left: 100%;
  top: 0;
}
.mainpage_personal__text p {
  position: relative;
  text-align: justify;
  padding-top: 20px;
  max-width: 800px;
  margin: 0;
  line-height: 1.3em;
}
.mainpage_personal__text p + p {
  margin-top: 45px;
  margin-left: 60px;
}
.mainpage_personal__text p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 9px;
  background-color: #d71b00;
}
.mainpage_personal__billet {
  position: relative;
  background-color: #fff;
  font-size: 30px;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 50px;
}
.mainpage_personal__billet::before {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 100vw;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 0;
}
.mainpage_personal__billet-inner {
  position: relative;
  padding: 30px 80px;
}
.mainpage_personal__billet-inner::before,
.mainpage_personal__billet-inner::after {
  content: "";
  position: absolute;
  color: #e1e6ec;
  width: 45px;
  height: 37px;
  background: url('/assets/img/ticks.png') center center / 100% no-repeat;
}
.mainpage_personal__billet-inner::before {
  left: 10px;
  top: 0;
}
.mainpage_personal__billet-inner::after {
  transform: scale(-1,-1);
  right: 5px;
  bottom: 0;
}
@media screen and (max-width: 1380px) {
  .mainpage_personal__content {
    grid-template-columns: 30% 1fr;
    column-gap: 65px;
    margin-bottom: 90px;
  }
  .mainpage_personal__text {
    font-size: 19px;
  }
  .mainpage_personal__text::before {
    width: 35px;
    height: 50%;
  }
  .mainpage_personal__text p + p {
    margin-top: 35px;
    margin-left: 45px;
  }
}
@media screen and (max-width: 1280px) {
  .mainpage_personal__sub {
    font-size: 30px;
  }
  .mainpage_personal__billet {
    font-size: 26px;
  }
  .mainpage_personal__billet-inner::before, 
  .mainpage_personal__billet-inner::after {
    width: 40px;
    height: 34px;
  }
  .mainpage_personal__billet-inner {
    padding: 25px 65px;
  }
}
@media screen and (max-width: 992px) {
  .mainpage_personal__sub {
    font-size: 26px;
  }
  .mainpage_personal__content {
    grid-template-columns: 35% 1fr;
    column-gap: 50px;
    margin-bottom: 60px;
  }
  .mainpage_personal__text {
    font-size: 18px;
  }
  .mainpage_personal__text::before {
    width: 25px;
  }
  .mainpage_personal__text p + p {
    margin-top: 25px;
    margin-left: 39px;
  }
  .mainpage_personal__billet {
    font-size: 22px;
  }
  .mainpage_personal__billet-inner::before, 
  .mainpage_personal__billet-inner::after {
    width: 34px;
    height: 30px;
  }
  .mainpage_personal__billet-inner {
    padding: 20px 50px;
  }
}
@media screen and (max-width: 768px) {
  .mainpage_personal__img {
    text-align: center;
    margin-bottom: 20px;
  }
  .mainpage_personal__sub {
    font-size: 22px;
    padding-top: 0;
  }
  .mainpage_personal__content {
    grid-template-columns: 100%;
  }
  .mainpage_personal__text {
    font-size: 18px;
  }
  .mainpage_personal__text::before {
    height: 100%;
  }
  .mainpage_personal__text p {
    padding-top: 15px;
  }
  .mainpage_personal__text p + p {
    margin-top: 25px;
    margin-left: 0px;
  }
  .mainpage_personal__text p::before {
    width: 40px;
    height: 6px;
  }
  .mainpage_personal__billet {
    font-size: 20px;
  }
  .mainpage_personal__billet-inner {
    padding: 15px 45px;
  }
  .mainpage_personal__billet-inner::before {
    left: 0px;
  }
  .mainpage_personal__billet-inner::after {
    right: 0px;
  }
}
@media screen and (max-width: 480px) {
  .mainpage_personal__billet {
    font-size: 18px;
    line-height: 1.5em;
  }
  .mainpage_personal__billet-inner {
    padding: 40px 0 35px;
  }
  .mainpage_personal__billet-inner::before, 
  .mainpage_personal__billet-inner::after {
    width: 30px;
    height: 26px;
  }
  .mainpage_personal__billet-inner::before {
    left: -5px;
  }
  .mainpage_personal__billet-inner::after {
    right: -5px;
  }
}

.mainpage_personal__list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 30px;
  row-gap: 50px;
  padding: 50px 0 0;
  margin-bottom: 25px;
}
.mainpage_personal__list li {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 18px 24px;
  background: linear-gradient(to bottom, transparent 0% 120px, #fff 120px 100%);
}
.mainpage_personal__link {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;  
  margin-bottom: 10px;  
}
.mainpage_personal__list li span {
  display: block;
  text-align: center;
}
.mainpage_personal__photo {
  position: relative;
  margin-bottom: 20px;
}
.mainpage_personal__photo::before {
  content: "";
  position: absolute;
  right: 18px;
  top: 18px;
  max-width: 72px;
  width: 22%;
  height: 90px;
  background: url("/assets/img/logo-watermark.svg") center top / 100% auto
    no-repeat;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(193deg)
    brightness(103%) contrast(101%);
}
.mainpage_personal__photo img {
  display: block;
}
.mainpage_personal__name {
  position: relative;
  font-family: var(--font-Prata);
  font-size: 20px;
  letter-spacing: 0.03em;
  line-height: 1.5em;
  padding-bottom: 15px;
  margin-bottom: 12px;
  max-width: 250px;
}
.mainpage_personal__name::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  max-width: 203px;
  height: 7px;
  background: url("/assets/img/mainpage_personal_decor.png") center center /
    100% no-repeat;
}
.mainpage_personal__post {
  font-size: 20px;
  margin-bottom: 15px;
}
.mainpage_personal__experience {
  font-size: 14px;
}
.mainpage_personal__controls {
  width: 100%;
  padding-top: 10px;
  margin-top: auto;
}
.mainpage_personal__btn {
  display: block;
  width: 100%;
  max-width: 145px;
  padding: 12px 6px;
  margin: 0 auto;
  font-size: 14px;
  border: none;
  border-radius: 40px;
  font-family: var(--font-Prata);
  font-size: 14px;
  background-color: #a7967d;
  color: #fff;
  cursor: pointer;
}
.mainpage_personal__btn:hover {
  background-color: #fff;
  color: #010101;
  box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.21);
}
@media screen and (max-width: 1380px) {
  .mainpage_personal {
    padding-top: 45px;
    padding-bottom: 45px;
  }
  .mainpage_personal__list {
    padding-top: 30px;
    column-gap: 20px;
  }
}
@media screen and (max-width: 1140px) {
  .mainpage_personal__list {
    grid-template-columns: repeat(2, 48%);
    justify-content: space-between;
    column-gap: initial;
    max-width: 900px;
    margin: 0 auto 20px;
  }
}
@media screen and (max-width: 768px) {
  .mainpage_personal {
    padding-top: 40px;
  }
  .mainpage_personal__list {
    padding-top: 10px;
  }
  .mainpage_personal p {
    line-height: 1.55em;
  }
  .mainpage_personal__list li {
    padding: 0 15px 20px;
  }
  .mainpage_personal__photo {
    margin-bottom: 15px;
  }
  .mainpage_personal__name {
    font-size: 20px;
  }
  .mainpage_personal__post {
    font-size: 20px;
  }
  .mainpage_personal__photo::before {
    right: 12px;
    top: 12px;
  }
}
@media screen and (max-width: 440px) {
  .mainpage_personal__list {
    grid-template-columns: 100%;
  }
}

.mainpage_license {
  padding-top: 45px;
  padding-bottom: 90px;
  overflow: hidden;
}
.mainpage_license h2 {
  padding-bottom: 30px;
}
.mainpage_license_btns {
  position: relative;
  width: 49%;
  display: flex;
  justify-content: space-between;
  padding: 0 50px;
  margin: -50px 0 0 auto;
  z-index: 10;
}
.mainpage_license_btns a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 68px;
  border-radius: 10px;
  color: var(--white);
  background-color: var(--beige);
  width: 48%;
  max-width: 260px;
  font-size: 15px;
  margin-bottom: 18px;
}
.mainpage_license__slider {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
}
.license-slider {
  padding-bottom: 60px;
}
.license-slider .swiper-wrapper {
  align-items: center;
}
.license-slider .swiper-slide {
  padding: 30px;
  cursor: pointer;
}
.license-slider .swiper-slide a {
  display: block;
  padding: 5px;
  margin: 0 auto;
  max-width: 298px;
  box-shadow: 0px 0px 29px rgba(0, 0, 0, 0.35);
}
.license-slider .swiper-slide img {
  display: block;
}
.license-slider .swiper-pagination {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: left;
  max-width: 49%;
  margin-bottom: 15px;
  padding-left: 30px;
}
.license-slider .swiper-pagination .swiper-pagination-bullet {
  margin: 0 20px 0 0;
}
.license-slider-prev,
.license-slider-next {
  position: absolute;
  top: 38%;
  width: 62px;
  height: 88px;
  background: url("/assets/img/arrow_right.svg") center center / 20px no-repeat,
    #e1e6ec;
  z-index: 5;
  cursor: pointer;
}
.license-slider-prev {
  left: -74px;
  transform: scale(-1, 1);
}
.license-slider-next {
  right: -74px;
}
@media screen and (max-width: 1700px) {
  .license-slider-prev {
    left: -20px;
  }
  .license-slider-next {
    right: -20px;
  }
}
@media screen and (max-width: 1480px) {
  .license-slider-prev {
    left: 0px;
  }
  .license-slider-next {
    right: 0px;
  }
  .license-slider .swiper-slide {
    padding: 20px;
  }
  .license-slider .swiper-slide a {
    box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.35);
  }
  .mainpage_license_btns {
    padding: 0;
  }
  .mainpage_license_btns a {
    min-height: 54px;
  }
}
@media screen and (max-width: 768px) {
  .mainpage_license {
    padding-top: 40px;
    padding-bottom: 60px;
  }
  .mainpage_license h2 {
    padding-bottom: 0;
  }
  .mainpage_license_btns {
    width: 100%;
    margin: 0;
    justify-content: center;
    column-gap: 20px;
  }
  .license-slider-prev,
  .license-slider-next {
    top: 35%;
    width: 45px;
    height: 60px;
  }
  .license-slider .swiper-pagination {
    max-width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .license-slider {
    padding-bottom: 30px;
    margin-bottom: 40px;
  }
}
@media screen and (max-width: 480px) {
  .mainpage_license .container {
    padding-left: 5px;
    padding-right: 5px;
  }
  .mainpage_license__slider {
    padding-left: 0;
    padding-right: 0;
  }
  .mainpage_license_btns {
    flex-direction: column;
    align-items: center;
  }
  .mainpage_license_btns a {
    width: 100%;
    margin-bottom: 15px;
  }
  .license-slider .swiper-slide {
    padding: 10px;
  }
  .license-slider .swiper-slide a {
    padding: 2px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.35);
  }
  .license-slider-prev,
  .license-slider-next {
    display: none;
  }
}

.scroll_top {
  display: block;
  position: sticky;
  width: 112px;
  height: 0px;
  margin-right: 70px;
  margin-left: auto;
  bottom: 0px;
  border: none;
  z-index: 10;
  font-size: 0px;
  padding: 0px;
}
.scroll_top::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 112px;
  background: url("/assets/img/arrow_up_red.svg") center center / 22px no-repeat,
    var(--dark);
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  left: 0;
  bottom: 100px;
}
@media screen and (max-width: 1400px) {
  .scroll_top {
    margin-right: 40px;
    width: 90px;
  }
  .scroll_top::before {
    height: 90px;
    bottom: 105px;
  }
}
@media screen and (max-width: 768px) {
  .scroll_top {
    margin-right: 20px;
    width: 70px;
  }
  .scroll_top::before {
    height: 70px;
    bottom: 20px;
  }
}
@media screen and (max-width: 480px) {
  .scroll_top {
    margin-right: 10px;
    width: 50px;
  }
  .scroll_top::before {
    height: 50px;
    bottom: 25px;
  }
}

.prices {
  padding: 25px 0 65px;
}
.prices h1,
.prices h2 {
  margin-bottom: 45px;
}
.prices_section {
  max-width: 1330px;
  margin: 0 auto 60px;
}
.price-block {
  margin-bottom: 20px;
}
.price-block.is-active .price-block_hidden {
  display: block;
}
.price-block_hidden {
  display: none;
  margin-bottom: 35px;
}
.price-block_header {
  position: relative;
  display: flex;
  align-items: center;
  min-height: 108px;
  padding: 15px 120px 15px 70px;
  border-radius: 10px;
  background-color: #edeae5;
  margin-bottom: 10px;
  font-family: var(--font-Prata);
  font-size: 25px;
}
.price-block_header::before,
.price-block_header::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  border-radius: 15px;
  top: 50%;
  transform: translateY(-50%);
  right: 30px;
}
.price-block_header::before {
  background: var(--white);
}
.price-block_header::after {
  background: url("/assets/img/arrow_right.svg") center center / 20px no-repeat;
  transition: transform 0.2s;
}
.price-block.is-active .price-block_header::after {
  transform: translateY(-50%) rotate(90deg);
}
.price-block_list li {
  display: grid;
  grid-template-columns: 1fr 230px 132px;
  align-items: center;
  column-gap: 20px;
  padding: 13px 45px 13px 70px;
}
.price-block_list li:nth-child(even),
.price-block_list li:only-child {
  background-color: #f0f2f5;
}
.price-block_list .entry-btn {
  min-height: 44px;
  font-size: 12px;
}
@media screen and (max-width: 1400px) {
  .price-block_header {
    font-size: 22px;
    min-height: 90px;
    padding: 10px 95px 10px 50px;
  }
  .price-block_header::before,
  .price-block_header::after {
    width: 65px;
    height: 65px;
    right: 20px;
  }
  .price-block_list li {
    grid-template-columns: 1fr 170px 132px;
    padding: 10px 35px 10px 50px;
  }
}
@media screen and (max-width: 1140px) {
  .prices_section {
    margin-bottom: 40px;
  }
  .prices h1,
  .prices h2 {
    margin-bottom: 30px;
  }
  .price-block_header {
    font-size: 20px;
    min-height: 80px;
    padding: 10px 85px 10px 30px;
  }
  .price-block_header::before,
  .price-block_header::after {
    width: 60px;
    height: 60px;
    right: 15px;
  }
  .price-block_list li {
    grid-template-columns: 1fr 90px 120px;
    padding: 8px 10px 8px 30px;
  }
}
@media screen and (max-width: 768px) {
  .price-block_header {
    font-size: 18px;
    min-height: 70px;
    padding-left: 20px;
  }
  .price-block_header::before,
  .price-block_header::after {
    width: 45px;
    height: 45px;
    border-radius: 8px;
  }
  .price-block_list li {
    padding-left: 20px;
    column-gap: 15px;
    font-size: 16px;
  }
}
@media screen and (max-width: 600px) {
  .price-block_list li {
    grid-template-columns: 1fr 70px 100px;
  }
  .price-block_list .entry-btn {
    min-height: 40px;
  }
}
@media screen and (max-width: 480px) {
  .prices h1,
  .prices h2 {
    margin-bottom: 20px;
  }
  .price-block_header {
    font-size: 17px;
  }
  .price-block_list li {
    grid-template-columns: 1fr auto 100px;
    padding: 5px 10px;
    column-gap: 10px;
    font-size: 15px;
  }
}
@media screen and (max-width: 400px) {
  .price-block_list li {
    grid-template-columns: 1fr 130px;
    padding: 8px;
    row-gap: 5px;
  }
  .price-block_list li span {
    grid-column: 1/-1;
  }
  .price-block_list .entry-btn {
    min-height: 36px;
  }
}

.reviews {
  padding: 25px 0 60px;
  background: linear-gradient(
    to bottom,
    transparent 0% 400px,
    var(--lightgray) 400px 100%
  );
  overflow: hidden;
}
.reviews h1 {
  margin-bottom: 45px;
}
.reviews_block {
  width: 100%;
  max-width: 1330px;
  background: url("/assets/img/reviews_block.jpg") center top / cover no-repeat,
    #0f0d0a;
  color: var(--white);
  padding: 130px 60px 60px;
  margin: 0 auto 60px;
  border-radius: 55px;
}
.reviews_block__row,
.reviews_block__btns {
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
}
.reviews_block__row {
  display: flex;
  margin-bottom: 50px;
}
.reviews_block__header {
  position: relative;
  font-family: var(--font-Prata);
  font-size: 35px;
  width: 45%;
  flex-shrink: 0;
  line-height: 1.3em;
  border-right: 1px solid var(--white);
  padding-right: 40px;
  margin-right: 60px;
}
.reviews_block__text p {
  margin-bottom: 0;
  max-width: 520px;
}
.reviews_block__text p + p {
  margin-top: 30px;
}
.reviews_block__btn {
  width: 100%;
  max-width: 320px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 8px;
  min-height: 68px;
  font-size: 15px;
  color: var(--white);
  border: none;
  background-color: var(--beige);
  cursor: pointer;
}
.reviews_block__btn span {
  display: block;
  position: relative;
  padding-left: 38px;
}
.reviews_block__btn span::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' standalone='no'%3F%3E%3Csvg height='22' version='1.1' viewBox='0 0 22 22' width='22' xml:space='preserve' id='svg39' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns='http://www.w3.org/2000/svg' xmlns:svg='http://www.w3.org/2000/svg'%3E%3Cg id='Guides__x26__Forms' /%3E%3Cg id='Icons' transform='translate(-5.0234858,-4.9839308)'%3E%3Cg id='g36'%3E%3Cpath d='m 6,6 v 15 h 6 v 5 l 5,-5 h 9 V 6 Z M 24,19 H 17 16.172 L 15.586,19.586 14,21.172 V 21 19 H 12 8 V 8 h 16 z' id='path28' style='fill:%23ffffff' /%3E%3Ccircle cx='11' cy='13.5' r='1.5' id='circle30' style='fill:%23ffffff' /%3E%3Ccircle cx='16' cy='13.5' r='1.5' id='circle32' style='fill:%23ffffff' /%3E%3Ccircle cx='21' cy='13.5' r='1.5' id='circle34' style='fill:%23ffffff' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A")
    center center / 100% no-repeat;
}

.reviews_slider .swiper-slide {
  display: flex;
  height: initial;
  padding: 10px;
}
.reviews_slider .review {
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.2);
}
.review {
  position: relative;
  background-color: var(--white);
  padding: 85px 50px 130px 65px;
  width: 100%;
}
.review::before {
  content: "";
  position: absolute;
  width: 16%;
  max-width: 72px;
  height: 90px;
  right: 30px;
  bottom: 20px;
  background: url("/assets/img/logo-watermark.svg") center center / 100%
    no-repeat;
  filter: invert(92%) sepia(7%) saturate(165%) hue-rotate(173deg)
    brightness(98%) contrast(98%);
}
.review_name {
  display: block;
  font-size: 20px;
  font-family: var(--font-Prata);
  margin-bottom: 12px;
}
.review_time {
  display: block;
  color: var(--beige);
  font-size: 14px;
  margin-bottom: 30px;
}
.review_text {
  font-size: 15px;
  line-height: 1.7em;
  min-height: 210px;
}
.reviews_slider {
  position: relative;
}
.reviews-slider {
  padding-bottom: 40px;
}
.reviews-slider-prev,
.reviews-slider-next {
  position: absolute;
  top: 44%;
  width: 64px;
  height: 64px;
  background: url("/assets/img/arrow_right.svg") center center / 20px no-repeat,
    #b9bdc2;
  z-index: 5;
  cursor: pointer;
}
.reviews-slider-prev {
  left: -75px;
  transform: scale(-1, 1);
}
.reviews-slider-next {
  right: -75px;
}
.reviews-slider .swiper-pagination {
  position: absolute;
  bottom: 0px;
}
.reviews-slider .swiper-pagination .swiper-pagination-bullet {
  margin: 0 10px;
}
.reviews-slider
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
  background-color: #b9bdc2;
}
@media screen and (max-width: 1700px) {
  .reviews-slider-prev {
    left: -20px;
  }
  .reviews-slider-next {
    right: -20px;
  }
}
@media screen and (max-width: 1480px) {
  .reviews_slider {
    padding-left: 20px;
    padding-right: 20px;
  }
  .reviews-slider-prev {
    left: 0px;
  }
  .reviews-slider-next {
    right: 0px;
  }
  .review {
    padding: 65px 35px 120px 45px;
  }
  .review_time {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 1280px) {
  .reviews_block__row {
    margin-bottom: 30px;
  }
  .reviews_block__header {
    font-size: 32px;
  }
  .reviews_block__text p + p {
    margin-top: 20px;
  }
}
@media screen and (max-width: 992px) {
  .reviews_block {
    padding: 70px 40px 40px;
  }
  .reviews_block__header {
    width: 42%;
    font-size: 28px;
    padding-right: 20px;
    margin-right: 30px;
  }
  .reviews_block__text p + p {
    margin-top: 10px;
  }
  .reviews_block__btn {
    max-width: 300px;
    min-height: 54px;
  }
}
@media screen and (max-width: 768px) {
  .review_text {
    min-height: initial;
    margin: 0;
  }
  .reviews-slider-prev,
  .reviews-slider-next {
    width: 50px;
    height: 50px;
  }
  .reviews-slider-prev {
    left: -5px;
  }
  .reviews-slider-next {
    right: -5px;
  }
  .reviews_block {
    padding: 30px;
    border-radius: 30px;
    margin-bottom: 40px;
  }
  .reviews_block__row {
    flex-wrap: wrap;
  }
  .reviews_block__header {
    font-size: 24px;
    width: 100%;
    padding: 0 0 20px 0;
    margin: 0 0 20px 0;
    border-right: none;
    border-bottom: 1px solid var(--white);
  }
}
@media screen and (max-width: 480px) {
  .reviews h1 {
    margin-bottom: 30px;
  }
  .reviews_slider {
    padding-left: 10px;
    padding-right: 10px;
  }
  .reviews-slider {
    padding-bottom: 20px;
  }
  .review {
    padding: 40px 30px 90px 35px;
  }
  .review_time {
    margin-bottom: 15px;
  }
  .review::before {
    right: 20px;
    bottom: 10px;
    height: 80px;
    background-position: center bottom;
  }
  .reviews_block {
    padding: 30px 20px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
  .reviews_block__header {
    font-size: 20px;
  }
  .reviews_block__btn {
    max-width: 280px;
    min-height: 48px;
  }
  .reviews_block__btn span {
    padding-left: 30px;
  }
}
.contacts {
  padding-top: 25px;
}
@media screen and (min-width: 769px) {
  .contacts h1 {
    margin-bottom: 45px;
  }
}
.contacts_block {
  position: relative;
  display: grid;
  grid-template-columns: 51% 47%;
  justify-content: space-between;
  max-width: 1330px;
  margin: 0 auto;
  background: url("/assets/img/contacts_block.jpg") center top / cover no-repeat;
  padding: 75px 45px 40px;
  border-radius: 50px;
  color: var(--white);
  z-index: 2;
}
@media screen and (max-width: 991px) {
  .contacts_block {
    align-items: center;
    grid-template-columns: 60% 38%;
    padding: 40px 30px;
  }
}
@media screen and (max-width: 768px) {
  .contacts_block {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 480px) {
  .contacts_block {
    padding: 30px 20px;
  }
}
.contacts_content {
  width: 100%;
  padding-top: 20px;
  max-width: 500px;
  margin-left: auto;
}
.contacts_content .contact-elem {
  display: block;
  padding-left: 38px;
  font-size: 18px;
  margin-bottom: 30px;
}
.contacts_content .contact-elem--time {
  margin-bottom: 35px;
}
.contacts_content .contact-elem::before {
  top: -2px;
  transform: none;
}
.contacts_content .contact-elem span {
  display: block;
  margin-bottom: 10px;
}
.contacts_phones {
  display: grid;
  grid-template-columns: repeat(2, auto);
  column-gap: 40px;
  justify-content: start;
  align-items: end;
}
.contacts_phones .whatsapp {
  grid-column: 2/-1;
  grid-row: 1/3;
  width: auto;
  height: auto;
}
.contacts_content .contacts_phones .contact-elem {
  margin-bottom: 0px;
}
.contacts_map {
  position: relative;
  margin-top: -270px;
  width: 100%;
  height: 660px;
}
@media screen and (max-width: 768px) {
  .contacts_content {
    max-width: 350px;
    margin: 0 auto;
  }
  .contacts_map {
    margin-top: -150px;
    height: 500px;
  }
}
.contacts_content__btns {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 16px;
  padding-top: 30px;
  padding-left: 25px;
}
.contacts_content__btns .entry-btn {
  flex: 1;
  border-radius: 8px;
  min-height: 65px;
}
.contacts_content__btns .messenger {
  width: 64px;
  height: 64px;
  border: 2px solid var(--white);
}
@media screen and (max-width: 1280px) {
  .contacts_content__btns {
    max-width: 400px;
  }
  .contacts_content__btns .entry-btn {
    min-height: 55px;
  }
}
@media screen and (max-width: 992px) {
  .contacts_content__btns {
    padding-top: 20px;
  }
  .contacts_content__btns .entry-btn {
    flex: initial;
    width: 100%;
    max-width: 240px;
    min-height: 48px;
  }
  .contacts_content .contact-elem,
  .contacts_content .contact-elem--time {
    margin-bottom: 20px;
  }
  .contacts_phones {
    column-gap: 20px;
  }
  .contacts_phones .whatsapp {
    max-width: 50px;
  }
}
.contacts_slider {
  position: relative;
  max-width: 620px;
}
.contacts-slider {
  padding-bottom: 30px;
}
.contacts-slider .swiper-slide {
  padding: 0 20px;
}
.contacts-slider .swiper-slide img {
  display: block;
}
.contacts-slider-prev,
.contacts-slider-next {
  position: absolute;
  top: 35%;
  width: 62px;
  height: 88px;
  background: url("/assets/img/arrow_right.svg") center center / 20px no-repeat,
    rgba(225, 230, 236, 0.9);
  z-index: 5;
  cursor: pointer;
}
.contacts-slider-prev {
  left: 0px;
  transform: scale(-1, 1);
}
.contacts-slider-next {
  right: 0px;
}
@media screen and (max-width: 768px) {
  .contacts_slider {
    max-width: 660px;
  }
  .contacts-slider {
    max-width: 620px;
  }
}
@media screen and (max-width: 480px) {
  .contacts-slider-prev,
  .contacts-slider-next {
    top: 50%;
    width: 40px;
    height: 66px;
    margin-top: -33px;
  }
}
.contacts-slider .swiper-pagination {
  position: absolute;
  bottom: 0px;
  left: 0;
  text-align: left;
  padding-left: 20px;
}
.contacts-slider .swiper-pagination .swiper-pagination-bullet {
  margin: 0 20px 0 0;
  background-color: rgba(211, 218, 227, 0.4);
}
.contacts-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #fff;
}
.form-entry {
  display: block;
  width: 100%;
}
/* popup */
.popup {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  background: rgba(0, 0, 0, 0.8);
}
.close-btn {
  position: relative;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  font-size: 0;
}
.close-btn::before,
.close-btn::after {
  content: "";
  position: absolute;
  top: 50%;
  width: 100%;
  height: 2px;
  background: #231f20;
}
.close-btn::before {
  transform: rotate(45deg);
  left: 0;
}
.close-btn::after {
  transform: rotate(-45deg);
  right: 0;
}
.popup_inner {
  position: relative;
  background-color: #fff;
  width: 100%;
  max-width: 1010px;
  padding: 0 0 0 390px;
}
.popup_inner::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 390px;
  height: 100%;
  background-position: center;
  background-size: cover;
}
.entry-popup .popup_inner::before {
  background-image: url("/assets/img/entry-popup.jpg");
}
.review-popup .popup_inner::before {
  background-image: url("/assets/img/review-popup.jpg");
}
.popup_content {
  padding: 80px 40px 65px;
}
@media screen and (max-width: 768px) {
  .popup_inner {
    padding: 0 0 0 200px;
  }
  .popup_inner::before {
    width: 200px;
  }
  .popup_content {
    padding: 40px 25px 45px;
  }
}
@media screen and (max-width: 480px) {
  .popup_inner {
    padding: 0;
  }
  .popup_inner::before {
    display: none;
  }
  .popup_content {
    padding: 40px 25px 30px;
  }
}
.popup_content h2,
.popup_content .h2 {
  position: relative;
  line-height: 1em;
  padding-bottom: 25px;
  margin-bottom: 25px;
}
.popup_content h2::before,
.popup_content .h2::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  width: 100%;
  max-width: 203px;
  height: 7px;
  background: url("/assets/img/mainpage_personal_decor.png") center center /
    100% no-repeat;
}
@media screen and (max-width: 520px) {
  .popup_content h2,
  .popup_content .h2 {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
  .popup_content h2::before,
  .popup_content .h2::before {
    height: 3px;
  }
}
.popup_content .form-entry {
  padding: 20px 15px 20px 25px;
  font-size: 15px;
  background-color: #eeeeee;
  border: none;
}
.popup_content textarea {
  min-height: 125px;
  resize: none;
}
@media screen and (max-width: 520px) {
  .popup_content .form-entry {
    padding: 15px;
  }
  .popup_content textarea {
    min-height: 100px;
  }
}
.popup_content .form-field {
  position: relative;
  margin-bottom: 23px;
}
.popup_content .form-error {
  position: absolute;
  bottom: -18px;
  font-size: 13px;
  color: red;
}
.popup .close-btn {
  position: absolute;
  width: 50px;
  height: 50px;
  top: 25px;
  right: 25px;
}
@media screen and (max-width: 768px) {
  .popup .close-btn {
    top: 5px;
    right: 5px;
  }
}
.popup-form {
  display: block;
  width: 100%;
  max-width: 420px;
  margin: 0 auto;
}
.popup_controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}
.popup_agreement {
  font-size: 12px;
  margin: 0;
  line-height: 1.35em;
  width: 46%;
  max-width: 170px;
}
.popup_btn {
  font-size: 15px;
  background-color: var(--beige);
  color: var(--white);
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 50%;
  flex-shrink: 0;
  padding: 25px 10px;
}
/* end popup */
.paragraph-decor {
  position: relative;
  padding-top: 40px;
}
.paragraph-decor::before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  left: 0;
  top: 0;
  background-color: var(--red);
}
@media screen and (max-width: 1140px) {
  .paragraph-decor {
    padding-top: 25px;
  }
}
/* Страница услуги */
.allservice {
  padding-top: 25px;
  padding-bottom: 80px;
}
.services-list {
  display: grid;
  grid-template-columns: repeat(3, 29%);
  justify-content: space-between;
  row-gap: 80px;
  font-size: 35px;
  padding-top: 30px;
  margin-bottom: 40px;
}
.services-item img {
  display: block;
}
.services-item_img {
  position: relative;
  display: block;
  padding-left: 25px;
  margin-bottom: 10px;
}
.services-item_img::before {
  content: "";
  position: absolute;
  display: block;
  z-index: -1;
  left: -15px;
  top: 13%;
  width: calc(100% - 20px);
  max-width: 385px;
  height: 100%;
  background-color: var(--lightgray);
}
.services-item_name {
  display: block;
  line-height: 1.4em;
  font-family: var(--font-Prata);
}
@media screen and (max-width: 1400px) {
  .services-list {
    grid-template-columns: repeat(3, 31.5%);
    row-gap: 60px;
    font-size: 32px;
  }
  .services-item_img::before {
    left: -10px;
  }
}
@media screen and (max-width: 1140px) {
  .services-list {
    padding-top: 10px;
    row-gap: 40px;
    font-size: 28px;
  }
}
@media screen and (max-width: 992px) {
  .services-list {
    font-size: 24px;
  }
  .services-item_name {
    line-height: 1.3em;
    padding-left: 5px;
  }
  .services-item_img {
    padding-left: 15px;
  }
  .services-item_img::before {
    left: -5px;
    top: 11%;
  }
}
@media screen and (max-width: 768px) {
  .allservice {
    padding-top: 10px;
    padding-bottom: 40px;
  }
  .services-list {
    grid-template-columns: repeat(2, 48%);
  }
}
@media screen and (max-width: 600px) {
  .services-list {
    font-size: 20px;
    row-gap: 30px;
  }
}
@media screen and (max-width: 440px) {
  .services-list {
    grid-template-columns: 100%;
  }
  .services-item_img::before {
    top: 40px;
  }
}
/* end Страница услуги */
.row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.row img {
  display: block;
}
.billet {
  position: relative;
  background-color: var(--dark);
  color: var(--white);
}
.service-page h2 {
  font-size: 35px;
}
@media screen and (max-width: 1280px) {
  .service-page h2 {
    font-size: 32px;
  }
}
@media screen and (max-width: 992px) {
  .service-page h2 {
    font-size: 28px;
  }
}
@media screen and (max-width: 600px) {
  .service-page h2 {
    font-size: 24px;
  }
}
.gdpr {
  position: fixed;
  top: auto;
  bottom: 0;
  height: auto;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0px 3px 38px 0px rgba(99, 99, 99, 0.38);
  padding: 15px 0;
  font-family: "Montserrat-Medium";
  z-index: 1000;
}
.gdpr .container {
  display: grid;
  grid-template-columns: 1fr 244px;
  grid-column-gap: 80px;
  align-items: center;
  max-width: 1560px;
}
.container:before, .container:after {
  display: none;
}
.gdpr p,
.gdpr small {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 14px;
  line-height: 1.3;
}
.gdpr .container > small {
  display: none;
}
.btn_close {
  grid-row: 1/3;
  grid-column: 2/3;
  display: block;
  margin: 0;
  padding: 20px;
  font-size: 14px;
  color: #000;
  text-align: center;
  text-transform: uppercase;
  border: none;
  background-color: #fff;
  cursor: pointer;
}
.btn_close:hover {
  color: #000;
}
@media screen and (max-width: 991px) {
  .gdpr .container {
    grid-template-columns: 1fr 200px;
    grid-column-gap: 40px;
  }
}
@media screen and (max-width: 680px) {
  .gdpr {
    padding: 20px 15px 15px;
  }
  .gdpr .container {
    grid-template-columns: 1fr 175px;
    grid-column-gap: 35px;
  }
  .gdpr p {
    grid-column: 1/-1;
    margin-bottom: 10px;
    font-size: 13px;
  }
  .gdpr p small {
    display: none;
  }
  .gdpr .container > small {
    display: block;
    font-size: 12px;
    font-family: "Montserrat-Regular";
  }
  .btn_close {
    grid-row: 2/3;
    grid-column: 2/3;
    font-size: 13px;
  }
}
@media screen and (max-width: 480px) {
  .gdpr {
    padding: 20px 9px 15px;
  }
}
@media screen and (max-width: 414px) {
  .gdpr {
    padding: 15px 0 10px;
  }
}
#gdpr_form {
  display: none;
}
.employees_gallery .yarl__container {
  background-color: rgb(19, 19, 19, 0.9);
}
.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 16px;
  padding: 30px 0;
}
.breadcrumbs li {
  display: flex;
  align-items: center;
}
.breadcrumbs li::after {
  content: "/";
  display: block;
  margin: 0 4px;
}
.breadcrumbs li:last-child::after {
  display: none;
}
@media screen and (max-width: 480px) {
  .breadcrumbs {
    font-size: 14px;
    padding: 20px 0;
  }
}
.service_gallery {
  padding: 35px 0;
  background-color: #e1e6ec;
}
.service_gallery h2 {
  margin: 0 0 75px;
}
.service__slider {
  max-width: 1530px;
  position: relative;
}
.service__slider img {
  display: block;
}
.service__slider .swiper-wrapper {
  margin-bottom: 45px;
}
.service__slider .swiper-slide-active {
  box-shadow: 1px 4px 8px 1px rgba(54, 52, 52, 0.13);
}
.service__slider .swiper-slide-active img {
  border: 2px solid #fff;
}
.service__slider .slider-prev, 
.service__slider .slider-next {
  background-color: rgba(255, 255, 255, 0.9);
  top: 42%;
}
.service__slider .slider-prev {
  left: -65px;
}
.service__slider .slider-next {
  right: -65px;
}
.service__slider .swiper-pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 25px;
  row-gap: 10px;
}
@media screen and (min-width: 1280px) {
  .service_gallery h2 {
    font-size: 42px;
  }
}
@media screen and (max-width: 1680px) {
  .service__slider .slider-prev {
    left: 5px;
  }
  .service__slider .slider-next {
    right: 5px;
  }
}
@media screen and (max-width: 1200px) {
  .service_gallery h2 {
    margin: 0 0 35px;
  }
}
@media screen and (max-width: 768px) {
  .service__slider .swiper-wrapper {
    margin-bottom: 25px;
  }
  .service_gallery h2 {
    margin: 0 0 25px;
  }
}
.banner-block {
  width: 100%;
  max-width: 1920px;
  margin: 40px auto 0;
  padding: 0 15px;
}
.banner-slider {
  position: relative;
  width: 100%;
  max-width: 100vw;
  margin: 20px auto 0;
}
.banner-slider img {
  display: block;
}
.banner-slider .swiper-pagination {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 5px;
  z-index: 5;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10px;
  width: 100%;
}
.banner-slider .swiper-pagination-bullet {
  cursor: pointer;
  position: relative;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #a5a3a3;
}
.banner-slider .swiper-pagination-bullet-active {
  background-color: #fff;
}

.information {
  padding-top: 20px;
  padding-bottom: 110px;
}
.information h1 {
  padding-bottom: 70px;
}
.information_block {
  position: relative;
  background: linear-gradient(to top, transparent 0% 50px, #a7967d 50px 100%);
  padding-top: 40px;
  margin-bottom: 110px;
  &::before,
  &::after {
    content: "";
    position: absolute;
    left: 0;
    width: 23%;
  }
  &::before {  
    top: 0;
    height: 40px;
    background-color: #fff;
  }
  &::after {  
    bottom: 0;
    height: 50px;
    background-color: #a7967d;
    z-index: -1;
  }
}
.information_grid {
  display: grid;
  grid-template-columns: 37% 62%;
  justify-content: space-between;
  row-gap: 20px;
}
.information_grid__img {
  position: relative;
  padding: 12px 10px;
  background-color: #a7967d;
  & img {
    display: block;
    margin: 0 auto;
  }
}
.information_grid__docs {
  font-size: 22px;
  padding-top: 12px;
  margin-bottom: 50px;
}
.information_grid__elem {
  background-color: #fff;
  padding: 15px 15px 15px 35px;
  margin-bottom: 9px;
}
.information_grid__sub {
  display: block;
  font-weight: bold;
  text-transform: uppercase;
}
.information_docs__list {
  text-transform: uppercase;
  padding-top: 12px;
  & li {
    margin-bottom: 19px;
  }
}
.information_docs__item {
  position: relative;
  display: block;
  padding-left: 58px;
  padding-top: 7px;
  text-decoration: underline;
  min-height: 31px;

  &::before {
    content: "";
    position: absolute;
    left: 14px;
    top: 0;
    width: 24px;
    height: 31px;
    background: url('/assets/img/download-icon.svg') center center / 100% no-repeat;
  }
}
@media screen and (max-width: 1280px) {
  .information_grid {
    grid-template-columns: 39% 60%;
  }
  .information_grid__docs {
    font-size: 20px;
  }
  .information h1 {
    padding-bottom: 20px;
  }
}
@media screen and (max-width: 992px) {
  .information_block {
    padding-top: 20px;
    background: linear-gradient(to top, transparent 0% 30px, #a7967d 30px 100%);
    margin-bottom: 60px;
    &::before {
      height: 20px;
    }
    &::after {
      height: 30px;
    }
  }
  .information_grid__docs {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .information_grid__elem {
    padding: 12px 10px 12px 20px;
  }
  .information_docs__list li {
    margin-bottom: 10px;
  }
  .information_docs__item {
    padding-left: 44px;
    padding-top: 4px;
    &::before {
      left: 5px;
    }
  }
}
@media screen and (max-width: 768px) {
  .information {
    padding-top: 10px;
    padding-bottom: 50px;
  }
  .information h1 {
    margin-top: 10px;
    padding-bottom: 0px;
  }
  .information_block {
    padding-top: 35px;
    padding-bottom: 40px;
  }
  .information_grid {
    grid-template-columns: 1fr;
    &__img,
    &__docs {
      padding: 0;
      margin: 0;
    }
  }
}
@media screen and (max-width: 480px) {
  .information_docs__item {
    display: flex;
    align-items: center;
    padding-left: 35px;
    font-size: 16px;
    padding-top: 0;
    &::before {
      left: 0;
    }
  }
  .information_grid__elem {
    padding-left: 15px;
  }
}

.information_gallery {
  background-color: #a7967d;
  padding-top: 40px;
  padding-bottom: 35px;
  
    &__slider {
    position: relative;
    padding: 0 60px;  
    @media screen and (max-width: 1480px) {
      padding: 0 15px;
    }
    & .slider-prev,
    & .slider-next {
      top: 49%;
      @media screen and (min-width: 1481px) {
        background-color: #fff;
        background-size: 30px;
      }
    }
  }
}
.information-slider {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  & img {
    display: block;
    margin: 0 auto;
  }
}
.site-creator {
  padding: 20px 0;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  font-size: 15px;

  &_link {
    display: inline-block;
    color: inherit;
    text-decoration: underline;
    font-weight: bold;
  }
  @media (max-width: 480px) {
    font-size: 14px;
  }
}


