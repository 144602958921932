/* страница Архитектура бровей */
.vizazh {
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 35px;
}
.vizazh h1 {
  margin-bottom: 65px;
}
.vizazh h2 {
  font-size: 35px;
}
.vizazh p {
  line-height: 1.5em;
}
.vizazh_intro {
  position: relative;
  padding-top: 55px;
  padding-bottom: 45px;
  margin-bottom: 60px;
}
.vizazh_intro::before {
  content: '';
  position: absolute;
  z-index: -1;
  width: 100%;
  left: 50%;
  height: 100%;
  top: 0;
  background-color: var(--lightgray);
  transform: translateX(-35%);
}
.vizazh_intro__img {
  width: 47%;
  padding-top: 95px;
}
.vizazh_intro__text {
  width: 49%;
}
.vizazh_intro__sub {
  margin-top: 80px;
  margin-bottom: 75px;
  font-weight: 600;
}
.vizazh_intro__list {
  display: grid;
  grid-template-columns: repeat(3, 31%);
  justify-content: space-between;
  row-gap: 20px;
}
.vizazh_intro__list li {
  position: relative;
  padding-top: 84px;
}
.vizazh_intro__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  background-color: var(--dark);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 65%;
}
.vizazh_intro__list li:nth-child(1)::before {
  background-image: url('/assets/img/vizazh_intro_01.svg');
}
.vizazh_intro__list li:nth-child(2)::before {
  background-image: url('/assets/img/vizazh_intro_02.svg');
  background-size: 75%;
}
.vizazh_intro__list li:nth-child(3)::before {
  background-image: url('/assets/img/vizazh_intro_03.svg');
}
.vizazh_intro__list li:nth-child(4)::before {
  background-image: url('/assets/img/vizazh_intro_04.svg');
}
.vizazh_intro__list li:nth-child(5)::before {
  background-image: url('/assets/img/vizazh_intro_05.svg');
  background-size: 75%;
}
.vizazh_intro__list li b {
  display: block;
  margin-bottom: 2px;
}
.vizazh_intro__list li p {
  line-height: 1.55em;
}
.vizazh_permanent {
  background: linear-gradient(to bottom, transparent 0% 32px, var(--lightgray) 32px calc(100% - 32px) , transparent calc(100% - 32px));
  margin-bottom: 65px;
}
.vizazh_permanent__row {
  align-items: center;
}
.vizazh_permanent__text {
  width: 50%;
  margin-bottom: 50px;
}
.vizazh_permanent__img {
  width: 37%;
}
.vizazh_permanent__img img {
  margin: 0 auto;
}
.vizazh_permanent h2 {
  text-align: left;
  line-height: 1.45em;
  margin-bottom: 50px;
}
.vizazh_preffs {
  background-color: var(--dark);
  color: var(--white);
  padding: 70px 0 35px;
  margin-top: 70px;
}
.vizazh_preffs__list {
  display: grid;
  grid-template-columns: repeat(3, 32%);
  justify-content: space-between;
  row-gap: 40px;
}
.vizazh_preffs__list li {
  position: relative;
  padding-top: 40px;
}
.vizazh_preffs__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 5px;
  background-color: var(--red);
}
.vizazh_preffs__list li h3 {
  text-align: left;
  font-size: 35px;
  margin: 0 0 30px;
}
.vizazh_preffs__wrap {
  margin-bottom: 95px;
}
.vizazh_holiday {
  margin-bottom: 145px;
}
.vizazh_holiday__row {
  position: relative;
  padding-top: 40px;
}
.vizazh_holiday__row::before {
  content: "";
  position: absolute;
  width: 150vw;
  right: -90px;
  height: 50%;
  top: 14%;
  background-color: var(--lightgray);
  z-index: -1;
}
.vizazh_holiday__text {
  width: 56%;
}
.vizazh_holiday__img {
  width: 40%;
  padding-top: 50px;
}
.vizazh_holiday__img img {
  margin-left: auto;
}
.vizazh_holiday__billet {
  position: relative;
  background-color: var(--dark);
  color: var(--white);
  padding: 50px 35px 40px 100px;
  max-width: 735px;
  margin-bottom: 60px;
}
.vizazh_holiday__billet::before {
  content: "";
  position: absolute;
  left: 5px;
  width: 50vw;
  height: 100%;
  top: 0;
  transform: translateX(-100%);
  background-color: var(--dark);
}
.vizazh_holiday__sub {
  font-weight: 600;
  margin-bottom: 35px;
}
.vizazh_holiday__list {
  display: grid;
  grid-template-columns: 46% 50%;
  max-width: 795px;
  justify-content: space-between;
  grid-row-gap: 20px;
}
.vizazh_holiday__list li {
  position: relative;
  padding-top: 10px;
  padding-left: 50px;
}
.vizazh_holiday__list li:last-child {
  grid-column: 1/-1;
  margin-top: 25px;
  max-width: 740px;
}
.vizazh_holiday__list li::before,
.vizazh_holiday__list li::after {
  content: '';
  position: absolute;
}
.vizazh_holiday__list li::before {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 3px solid #0e0d0d;
  left: 0;
  top: 0;
}
.vizazh_holiday__list li::after {
  top: 2px;
  left: 7px;
  width: 32px;
  height: 15px;
  border: 4px solid #f40f0f;
  border-top: none;
  border-right: none;
  transform: rotate(-45deg);
}
.vizazh_holiday__list li b {
  display: block;
  margin-bottom: 15px;
}
.vizazh_holiday__list li p {
  margin-bottom: 0;
  line-height: 1.55em;
}
.vizazh_wedding__row {
  padding-top: 40px;
  padding-bottom: 40px;
  align-items: flex-start;
}
.vizazh_wedding__img {
  position: relative;
  width: 48%;
  padding-right: 20px;
  padding-bottom: 2%;
}
.vizazh_wedding__img::before {
  content: "";
  position: absolute;
  width: 50vw;
  right: 0;
  bottom: 0;
  height: 52%;
  background-color: var(--lightgray);
  z-index: -1;
}
.vizazh_wedding__text {
  width: 48.5%;
}
.vizazh_wedding__list li {
  position: relative;
  padding-top: 40px;
  margin-bottom: 40px;
}
.vizazh_wedding__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 50px;
  height: 5px;
  background-color: var(--red);
}
.vizazh_wedding__list li h3 {
  text-align: left;
  font-size: 35px;
  margin: 0 0 30px;
}
@media screen and (min-width: 1800px) {
  .vizazh_intro::before {
    transform: translateX(-500px);
  }
}
@media screen and (max-width: 1400px) {
  .vizazh_intro__sub {
    margin-top: 50px;
    margin-bottom: 45px;
  }
  .vizazh_holiday {
    margin-bottom: 95px;
  }
}
@media screen and (max-width: 1280px) {
  .vizazh h2,
  .vizazh_preffs__list li h3,
  .vizazh_wedding__list li h3 {
    font-size: 32px;
  }
  .vizazh_intro__list {
    grid-template-columns: repeat(2, 48.5%);
    row-gap: 10px;
  }
  .vizazh_intro__img {
    padding-top: 50px;
  }
  .vizazh_permanent__text {
    padding: 40px 0;
    margin-bottom: 0;
  }
  .vizazh_permanent h2 {
    margin-bottom: 30px;
  }
  .vizazh_holiday__billet {
    padding-left: 20px;
    margin-bottom: 30px;
  }
  .vizazh_holiday__list li:last-child {
    margin-top: 0;
  }
}
@media screen and (max-width: 1140px) {
  .vizazh_preffs__list li,
  .vizazh_wedding__list li {
    padding-top: 25px;
  }
  .vizazh_preffs__list li h3,
  .vizazh_wedding__list li h3 {
    margin-bottom: 20px;
  }
  .vizazh_holiday__row,
  .vizazh_wedding__row {
    padding-top: 10px;
  }
  .vizazh_holiday__billet {
    max-width: initial;
  }
}
@media screen and (max-width: 992px) {
  .vizazh h2,
  .vizazh_preffs__list li h3,
  .vizazh_wedding__list li h3 {
    font-size: 28px;
  } 
  .vizazh_wedding__list li {
    margin-bottom: 20px;
  }
  .vizazh_intro {
    background-color: var(--lightgray);
  }
  .vizazh_intro::before {
    display: none;
  }
  .vizazh_intro__img {
    width: 100%;
    padding-top: 0;
  }
  .vizazh_intro__img img {
    margin: 0 auto 25px;
  }
  .vizazh_intro__text {
    width: 100%;
  }
  .vizazh_intro__list {
    grid-template-columns: repeat(3, 31%);
  }
  .vizazh_intro__sub {
    margin-top: 30px;
    margin-bottom: 25px;
  }
  .vizazh_preffs__list {
    grid-template-columns: repeat(2, 48%);
    row-gap: 15px;
  }
  .vizazh_holiday__row::before {
    height: 75%;
    top: auto;
    bottom: 0;
  }
  .vizazh_holiday__text {
    width: 100%;
    padding-bottom: 20px;
  }
  .vizazh_holiday__img {
    order: -1;
    width: 100%;
    padding-top: 0;
  }
  .vizazh_holiday__img img {
    margin: 0 auto 25px;
  }
  .vizazh_wedding__img {
    width: 100%;
    padding-bottom: 20px;
    margin-bottom: 35px;
  }
  .vizazh_wedding__img img {
    margin: 0 auto;
  }
  .vizazh_wedding__text {
    width: 100%;
  }
  .vizazh_permanent__img {
    width: 40%;
  }
  .vizazh_permanent__text {
    width: 54%;
  }
}
@media screen and (max-width: 768px) {
  .vizazh_holiday {
    margin-bottom: 50px;
  }
  .vizazh_permanent {
    background: linear-gradient(to bottom, transparent 0% 50px, var(--lightgray) 50px 100%);
    margin-bottom: 40px;
  }
  .vizazh_permanent__img {
    width: 100%;
    order: -1;
  }
  .vizazh_permanent__text {
    width: 100%;
    padding: 20px 0;
  }
  .vizazh_preffs {
    margin-top: 30px;
  }
}
@media screen and (max-width: 600px) {
  .vizazh h1 {
    margin-bottom: 30px;
  }
  .vizazh h2,
  .vizazh_preffs__list li h3,
  .vizazh_wedding__list li h3 {
    font-size: 24px;
  }
  .vizazh_intro__list {
    grid-template-columns: repeat(2, 48.5%);
  }
  .vizazh_holiday__list,
  .vizazh_preffs__list {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 480px) {
  .vizazh_intro__list {
    grid-template-columns: 100%;
  }
  .vizazh_intro__list li {
    padding-top: 0;
    padding-left: 84px;
  }
}
/* end страница Архитектура бровей */