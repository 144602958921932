  .collarium {
    padding-top: 25px;
    overflow: hidden;
  }
  .collarium h1 {
    margin-bottom: 70px;
  }
  .collarium h2 {
    font-size: 35px;
  }
  .collarium p {
    line-height: 1.55em;
  }
  .collarium_intro {
    position: relative;
    padding-top: 75px;
    padding-bottom: 80px;
    overflow-x: hidden;
  }
  .collarium_intro::before {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    top: 0;
    right: 0;
    width: 77%;
    height: 75%;
    background-color: var(--lightgray);
  }
  .collarium_intro__img {
    position: relative;
    width: 38.5%;
    padding-top: 25px;
    z-index: 2;
  }
  .collarium_intro__text {
    position: relative;
    width: 59.5%;
    padding-top: 40px;
  }
  .collarium_intro__text p {
    max-width: 780px;

    &:first-child {
      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        left: 0;
        top: 0;
        background-color: var(--red);
      }
      @media screen and (max-width: 1140px) {
        padding-top: 25px;
      }
    }
  }
  .collarium_intro__billet {
    position: relative;
    background-color: var(--dark);
    color: var(--white);
    padding: 48px 30px 60px 160px;
    margin-top: 55px;
  }
  .collarium_intro__billet::before {
    content: "";
    position: absolute;
    left: 5px;
    top: 0;
    width: 25%;
    height: 100%;
    background-color: var(--dark);
    transform: translateX(-100%);
  }
  .collarium_intro__billet p {
    max-width: 630px;
  }
  .collarium_difference {
    background-color: var(--lightgray);
    padding-top: 90px;
    padding-bottom: 50px;
    margin-bottom: 50px;
  }
  .collarium_intro__row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .collarium_difference__text {
    width: 52%;
    max-width: 720px;
  }
  .collarium_difference__text p {
    max-width: 620px;
  }
  .collarium_difference__img {
    width: 46%;
  }
  .collarium_difference__img img {
    margin: 0 auto;
  }
  .collarium_difference h2 {
    margin: 15px 0 50px;
    text-align: left;
  }
  .collarium_effect {
    padding-top: 35px;
    margin-bottom: 50px;
  }
  .collarium_effect h2 {
    margin-bottom: 55px;
  }
  .collarium_effect__sub {
    text-align: center;
  }
  .collarium_effect__sub b {
    display: block;
  }
  .collarium_effect__list {
    display: grid;
    grid-template-columns: repeat(3, 30%);
    justify-content: space-between;
    row-gap: 25px;
    padding-top: 50px;
  }
  .collarium_effect__list li {
    position: relative;
    padding: 0 0 40px;
  }
  .collarium_effect__list li::before {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    width: 110%;
    left: -5%;
    bottom: 0;
    height: 78%;
  }
  .collarium_effect__list li:nth-child(even)::before {
    background-color: var(--lightgray);
  }
  .collarium_effect__list li img {
    display: block;
    margin: 0 auto;
  }
  .collarium_effect__name {
    text-align: left;
    font-size: 35px;
    margin: 50px 0 40px;
  }
  .collarium_usage {
    padding: 35px 0 55px;
    background: linear-gradient(to bottom, transparent 0% 30%, var(--lightgray) 30% 76%, transparent 76% 100%);  
    overflow-x: hidden;
  }
  .collarium_usage h2 {
    text-align: left;
  }
  .collarium_usage__row {
    align-items: flex-start;
    padding-top: 10px;
  }
  .collarium_usage__img {
    width: 38%;
    padding-top: 20px;
  }
  .collarium_usage__text {
    position: relative;
    background-color: var(--dark);
    color: var(--white);
    width: 58%;
    padding: 45px 40px 40px 140px;
  }
  .collarium_usage__text::before {
    content: "";
    position: absolute;
    left: calc(100% - 1px);
    top: 0;
    width: 50vw;
    height: 100%;
    background-color: var(--dark);
  }
  .collarium_usage__text p {
    max-width: 620px;
  }
  @media screen and (max-width: 1400px) {
    .collarium h1 {
      margin-bottom: 50px;
    }
    .collarium_intro {
      padding-top: 45px;
      padding-bottom: 50px;
    }
    .collarium_intro__text {
      width: 58%;
    }
    .collarium_intro__billet {
      padding: 40px 30px 50px 80px;
      margin-top: 40px;
    }
    .collarium_difference {
      padding-top: 50px;
      padding-bottom: 40px;
      margin-bottom: 20px;
    }
    .collarium_difference h2 {
      margin-bottom: 30px;
    }
    .collarium_effect__list {
      padding-top: 30px;
    }
    .collarium_effect__name {
      font-size: 30px;
      margin: 30px 0 35px;
    }
    .collarium_usage__text {
      padding-left: 80px;
    }
  }
  @media screen and (max-width: 1280px) {
    .collarium h2 {
      font-size: 32px;
    }
  }
  @media screen and (max-width: 1140px) {
    .collarium_intro {
      padding-top: 40px;
    }
    .collarium_intro__billet {
      padding: 30px 20px 30px 25px;
      margin-top: 30px;
    }
    .collarium_effect {
      padding-top: 20px;
      margin-bottom: 20px;
    }
    .collarium_effect__list li {
      padding-bottom: 20px;
    }
    .collarium_usage__text {
      padding: 30px 20px 25px 40px;
    }
  }
  @media screen and (max-width: 992px) {
    .collarium h2 {
      font-size: 28px;
    }
    .collarium_effect__name {
      font-size: 24px;
      margin: 20px 0 25px;
    }
    .collarium_intro__row {
      align-items: flex-start;
    }
    .collarium_effect h2 {
      margin-bottom: 30px;
    }
    .collarium_effect__list {
      grid-template-columns: repeat(2, 47%);
    }
  }
  @media screen and (max-width: 768px) {
    .collarium_intro::before {
      width: 100%;
      height: 95%;
    }
    .collarium_intro__img {
      width: 100%;
      padding: 0;
    }
    .collarium_intro__img img {
      margin: 0 auto 35px;
    }
    .collarium_intro__text {
      width: 100%;
    }
    .collarium_intro__billet::before,
    .collarium_usage__text::before {
      width: 100vw;
      left: 50%;
      transform: translateX(-50%);
    }
    .collarium_difference__text {
      width: 100%;
    }
    .collarium_difference__img {
      width: 100%;
      order: -1;
      margin-bottom: 20px;
    }
    .collarium_effect__list li {
      padding-bottom: 10px;
    }
    .collarium_effect__list li::before {
      width: calc(100% + 20px);
      left: -10px;
    }
    .collarium_usage {
      padding-top: 10px;
    }
    .collarium_usage__img {
      width: 100%;
      padding-top: 0;
    }
    .collarium_usage__img img {
      margin: 0 auto;
    }
    .collarium_usage__text {
      width: 100%;
    }
  }
  @media screen and (max-width: 600px) {
    .collarium h2 {
      font-size: 24px;
    }
    .collarium_effect__list {
      grid-template-columns: 100%;
    }
    .collarium_intro__billet {
      padding: 30px 0;
    }
    .collarium_usage__text {
      padding: 30px 0;
    }
  }