/* Страница Маникюр */
.manicure {
    overflow: hidden;
    padding-top: 25px;
    padding-bottom: 60px;
  }
  .manicure h1 {
    margin-bottom: 95px;
  }
  .manicure_intro {
    margin-bottom: 105px;
  }
  .manicure_intro__img {
    width: 36%;
    padding-top: 55px;
  }
  .manicure_intro__img img {
    margin: 0 auto;
  }
  .manicure_intro__text {
    width: 60%;
    max-width: 910px;
  }
  .manicure_intro__billet {
    padding: 50px 0 20px 0;
  }
  .manicure_intro__billet::before {
    content: "";
    position: absolute;
    display: block;
    width: 100vw;
    height: 160%;
    left: -35%;  
    top: 0;
    background-color: var(--dark);
    z-index: -1;
  }
  .manicure_intro__billet p {
    margin-bottom: 30px;
    max-width: 780px;

    &:first-child {
      position: relative;
      padding-top: 40px;
      
      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        left: 0;
        top: 0;
        background-color: var(--red);
      }
      @media screen and (max-width: 1140px) {
        padding-top: 25px;
      }
    }
  }
  .manicure_intro__billet p:last-child {
    margin-bottom: 0px;
  }
  .manicure_intro__list-wrap h2 {
    text-align: left;
  }
  .manicure_intro__list-wrap {
    position: relative;
    padding: 50px 30px 30px 55px;
    margin-left: 100px;
    z-index: 1;
  }
  .manicure_intro__list-wrap::before {
    content: "";
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100%;
    background-color: var(--lightgray);
    z-index: -1;
  }
  .manicure_intro__list li {
    position: relative;
    padding-top: 12px;
    margin-bottom: 25px;
    line-height: 1.55em;
    max-width: 620px;
  }
  .manicure_intro__list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 5px;
    background-color: var(--red);
  }
  .manicure_intro__list li b {
    display: block;
  }
  .manicure_reasons {
    margin-bottom: 115px;
  }
  .manicure_reasons__sub {
    font-size: 20px;
    font-weight: 600;
  }
  .manicure_reasons__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 9px;
    row-gap: 45px;
    padding-top: 25px;
  }
  .manicure_reasons__list li {
    display: flex;
    flex-direction: column;
  }
  .manicure_reasons__list li b {
    display: flex;
    align-items: center;
    min-height: 64px;
    padding: 15px 20px 15px 35px;
    margin-right: 28px;
  }
  .manicure_reasons__list li p {
    flex: 1;
    padding: 15px 10px 20px 20px;
    margin: -7px 0 0 12px;
    line-height: 1.55em;
  }
  .manicure_reasons__list li:nth-child(2) b,
  .manicure_reasons__list li:nth-child(4) b,
  .manicure_reasons__list li:nth-child(5) b,
  .manicure_reasons__list li:nth-child(7) b {
    background-color: var(--dark);
    color: var(--white);
  }
  .manicure_reasons__list li:nth-child(2) p,
  .manicure_reasons__list li:nth-child(4) p,
  .manicure_reasons__list li:nth-child(5) p,
  .manicure_reasons__list li:nth-child(7) p {
    background-color: var(--lightgray);
  }
  .manicure_pedicure__wrap h2 {
    text-align: left;
  }
  .manicure_pedicure__row {
    padding-top: 65px;
    row-gap: 15px;
  }
  .manicure_pedicure__text {
    position: relative;
    width: 60%;
    max-width: 890px;
    padding: 55px 0 0;
  }
  .manicure_pedicure__text::before {
    content: "";
    position: absolute;
    display: block;
    z-index: -1;
    width: 100vw;
    height: calc(100% + 85px);
    background-color: var(--lightgray);
    top: 0;
    right: -30%;
  }
  .manicure_pedicure__text p {
    margin-bottom: 30px;

    &:first-child {
      position: relative;
      padding-top: 40px;
      &::before {
        content: "";
        position: absolute;
        width: 50px;
        height: 5px;
        left: 0;
        top: 0;
        background-color: var(--red);
      }
      @media screen and (max-width: 1140px) {
        padding-top: 25px;
      }
    }
  }
  .manicure_pedicure__img {
    position: relative;
    width: 36%;
    margin-top: -120px;
  }
  .manicure_pedicure__img img {
    margin-left: auto;
  }
  .manicure_pedicure__billet {
    width: 100%;
    max-width: 930px;
    margin: 0 auto;
  }
  .manicure_pedicure__billet {
    padding: 40px 30px 15px;
    text-align: center;
  }
  .manicure_pedicure__billet p {
    position: relative;
    padding-top: 35px;
    line-height: 1.7em;
  }
  .manicure_pedicure__billet p::before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    width: 140px;
    height: 5px;
    background-color: var(--red);
  }
  .manicure_pedicure__billet a {
    font-weight: 600;
    white-space: nowrap;
    font-size: 1.3em;
  }
  @media screen and (min-width: 1680px) {
    .manicure_reasons__list {
      min-width: calc(100% + 70px);
      margin-left: -35px;
    }
  }
  @media screen and (min-width: 1800px) {
    .manicure_intro__billet::before {
      left: -350px;
    }
    .manicure_pedicure__text::before {
      right: -300px;
    }
  }
  
  @media screen and (max-width: 1400px) {
    .manicure h1 {
      margin-bottom: 50px;
    }
    .manicure_intro__list-wrap {
      margin-left: 50px;
    }
    .manicure_intro__list li {
      margin-bottom: 20px;
    }
    .manicure_intro,
    .manicure_reasons {
      margin-bottom: 75px;
    }
  }
  @media screen and (max-width: 1200px) {
    .manicure_intro__billet p {
      margin-bottom: 20px;
    }
    .manicure_intro__list-wrap {
      margin-left: 0px;
      padding: 25px 15px 15px 30px;
    }
    .manicure_reasons__list {
      column-gap: 5px;
      row-gap: 30px;
      padding-top: 15px;
    }
    .manicure_reasons__list li b {
      padding: 15px 15px 15px 25px;
      margin-right: 20px;
    }
    .manicure_reasons__list li p {
      padding: 15px 10px 15px 15px;
      margin: -5px 0 0 10px;
    }
  }
  @media screen and (max-width: 992px) {
    .manicure_intro__img,
    .manicure_pedicure__img {
      padding-top: 0;
      width: 100%;
      margin-top: 0;
      margin-bottom: 25px;
      order: -1;
    }  
    .manicure_intro__text {
      width: 100%;
    }
    .manicure_pedicure__text {
      padding-top: 40px;
      width: 100%;
    }
    .manicure_pedicure__row {
      padding-top: 0;
    }
    .manicure_pedicure__img img {
      margin: 0 auto;
    }
    .manicure_intro__billet::before,
    .manicure_pedicure__text::before {
      left: 50%;
      transform: translateX(-50%);
    }
    .manicure_intro__list-wrap::before {
      width: 100%;
    }
    .manicure_reasons__list {
      grid-template-columns: repeat(2, 1fr);
    }
    .manicure_reasons__list li:nth-child(3) b,
    .manicure_reasons__list li:nth-child(6) b {
      background-color: var(--dark);
      color: var(--white);
    }
    .manicure_reasons__list li:nth-child(3) p,
    .manicure_reasons__list li:nth-child(6) p {
      background-color: var(--lightgray);
    }
    .manicure_reasons__list li:nth-child(4) b,
    .manicure_reasons__list li:nth-child(4) p,
    .manicure_reasons__list li:nth-child(5) b,
    .manicure_reasons__list li:nth-child(5) p {
      background: none;
      color: inherit;
    }
    .manicure_pedicure__billet {
      padding: 30px 15px 15px;
    }
    .manicure_pedicure__billet p {
      padding-top: 20px;
    }
    .manicure_pedicure__billet br {
      display: none;
    }
    .manicure_intro, .manicure_reasons {
      margin-bottom: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    .manicure_reasons__list {
      grid-template-columns: 1fr;
      row-gap: 20px;
      padding-top: 0;
    }
    .manicure_reasons__list li:nth-child(even) b {
      background-color: var(--dark);
      color: var(--white);
    }
    .manicure_reasons__list li:nth-child(even) p {
      background-color: var(--lightgray);
    }
    .manicure_reasons__list li:nth-child(odd) b,
    .manicure_reasons__list li:nth-child(odd) p {
      background: none;
      color: inherit;
      padding-bottom: 0;
    }
  }
  /* end Страница Маникюр */