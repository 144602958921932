.sharko {
    padding-top: 25px;
  }
  .sharko h1 {
    margin-bottom: 65px;
  }
  .sharko p {
    line-height: 1.55;
  }
  .sharko_intro {
    position: relative;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto 90px;
    overflow: hidden;
  }
  .sharko_intro__img {
    position: absolute;
    width: 35.5%;
    top: 58px;
    left: 0;
  }
  .sharko_intro__img img {
    display: block;
  }
  .sharko_intro__content {
    width: 83%;
    background-color: var(--lightgray);
    padding: 75px 50px 95px 24%;
    margin-left: auto;
  }
  .sharko_intro .paragraph-decor {
    max-width: 780px;
  }
  .sharko_intro__list {
    column-count: 2;
    max-width: 780px;
    padding-top: 65px;
  }
  .sharko_intro__list li {
    position: relative;
    min-height: 66px;  
    padding-left: 90px;
    padding-top: 25px;
    overflow: hidden; 
    -webkit-column-break-inside: avoid; 
    page-break-inside: avoid;
    break-inside: avoid-column;
    margin-bottom: 23px;
    font-weight: 600;
    font-size: 16px;
  }
  .sharko_intro__list li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: var(--dark);
    background-position: center;
    background-repeat: no-repeat;
    background-size: 55%;
  }
  .sharko_intro__list li:nth-child(1):before {
    background-image: url(/assets/img/sharko_list_01.svg);
    background-size: 60%;
  }
  .sharko_intro__list li:nth-child(2):before {
    background-image: url(/assets/img/sharko_list_02.svg);
    background-size: 50%;  
  }
  .sharko_intro__list li:nth-child(3):before {
    background-image: url(/assets/img/sharko_list_03.svg);
    background-size: 80%;
  }
  .sharko_intro__list li:nth-child(4):before {
    background-image: url(/assets/img/sharko_list_04.svg);
    background-size: 65%;
  }
  .sharko_intro__list li:nth-child(5):before {
    background-image: url(/assets/img/sharko_list_05.svg);
    background-size: auto;
    background-position: 60% 60%;
  }
  .sharko_intro__list li:nth-child(6):before {
    background-image: url(/assets/img/sharko_list_06.svg);
  }
  .sharko_intro__list li:nth-child(7):before {
    background-image: url(/assets/img/sharko_list_07.svg);
  }
  .sharko_imgs {
    display: grid;
    grid-template-columns: repeat(3, 30.5%);
    justify-content: space-between;
    row-gap: 25px;
    margin-bottom: 100px;
  }
  .sharko_outro {
    background: linear-gradient(to bottom, transparent 0% 112px, var(--lightgray) 112px 100%);
    padding-bottom: 70px;
  }
  .sharko_outro__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .sharko_outro__text {
    width: 48%;
  }
  .sharko_outro__text p {
    margin-bottom: 30px;
    max-width: 690px;
  }
  .sharko_outro__billet {
    position: relative;
    color: #fff;
    background-color: var(--dark);
    padding: 48px 10px 30px 100px;
    margin-bottom: 55px;
  }
  .sharko_outro__billet::before {
    content: "";
    position: absolute;
    top: 0;
    right: 1px;
    width: 36%;
    height: 100%;
    background-color: var(--dark);
    transform: translateX(100%);
  }
  .sharko_outro__billet p {
    max-width: 560px;
  }
  .sharko_outro__img {
    position: relative;
    width: 48%;
    margin-top: 112px;
  }
  .sharko_outro__img img {
    display: block;
    margin-left: auto;
  }
  @media screen and (max-width: 1480px) {
    .sharko_intro__img {
      width: 40%;
      top: 40px;
    }
    .sharko_intro__content {
      width: 80%;
      padding: 50px 50px 60px 24%;
    }
    .sharko_intro__list {
      padding-top: 30px;
    }
    .sharko_imgs {
      grid-template-columns: repeat(3, 32%)
    }
    .sharko_outro {
      background: linear-gradient(to bottom, transparent 0% 75px, var(--lightgray) 75px 100%);
      padding-bottom: 50px;
    }
    .sharko_outro__billet {
      padding: 35px 10px 20px 60px;
      margin-bottom: 40px;
    }
    .sharko_outro__img {
      margin-top: 75px; 
    }
  }
  @media screen and (max-width: 1280px) {
    .sharko {
      padding-top: 0;
    }
    .sharko h1 {
      margin-bottom: 40px;
    }
    .sharko_intro {
      margin-bottom: 60px;
    }
    .sharko_intro__list {
      padding-top: 10px;
    }
    .sharko_intro__list li {
      margin-bottom: 15px;
    }
    .sharko_imgs {
      margin-bottom: 60px;
    }
    .sharko_outro__text p {
      margin-bottom: 20px;
    }
    .sharko_outro__billet {
      margin-bottom: 25px;
    }
  }
  @media screen and (max-width: 1140px) {
    .sharko_intro__img {
      width: 45%;
    }
    .sharko_intro__content {
      width: 75%;
      padding: 40px 20px 35px 23%;
    }  
    .sharko_intro__list li {
      min-height: 60px;
      padding-left: 70px;
      padding-top: 18px;
    }
    .sharko_intro__list li::before {
      width: 60px;
      height: 60px;
    }
    .sharko_intro__list li br {
      display: none;
    }
    .sharko_outro__text {
      width: 50%;
    }
    .sharko_outro__billet {
      padding: 25px 5px 5px 25px;
    }
  }
  @media screen and (max-width: 992px) {
    .sharko_intro__list {
      column-count: initial;
      padding-top: 0;
    }
    .sharko_intro__list li {
      display: flex;
      align-items: center;
      padding-top: 0;
      margin-bottom: 10px;
    }
    .sharko_imgs {
      grid-template-columns: repeat(2, 48.5%);
    }
    .sharko_outro__text {
      width: 100%;
    }
    .sharko_outro__billet::before {
      display: none;
    }
    .sharko_outro__img {
      width: 100%;
      margin: 0;
      order: -1;
    }
    .sharko_outro__img img {
      margin: 0 auto 30px;
    }
    .sharko_outro__text p {
      max-width: initial;
    }
    .sharko_outro__billet {
      padding: 30px 20px 15px 35px;
    }
  }
  @media screen and (max-width: 860px) {
    .sharko h1 {
      margin-bottom: 25px;
    }
    .sharko_intro__img {
      position: static;
      width: 100%;
      text-align: center;
    }
    .sharko_intro__img img {
      margin: 0 auto 20px;
    }
    .sharko_intro__content {
      width: 100%;
      padding: 40px 20px 35px 40px;
    }
    .sharko_intro__list {
      column-count: 2;
    }  
  }
  @media screen and (max-width: 600px) {
    .sharko_intro__content {
      padding: 30px 10px 25px 20px;
    }
    .sharko_intro__list {
      column-count: initial;
    }
    .sharko_intro__list li {
      min-height: 52px;
      padding-left: 60px;
    }
    .sharko_intro__list li::before {
      width: 52px;
      height: 52px;
    }
    .sharko_imgs {
      grid-template-columns: auto;
      justify-content: center;
      row-gap: 15px;
    }
  }