/* Страница Парикмахерская */
.hairdress {
  overflow: hidden;
  padding-top: 25px;
  padding-bottom: 90px;
}
.hairdress h1 {
  margin-bottom: 85px;
}
.hairdress_intro__text {
  width: 52%;
  padding-top: 40px;
}
.hairdress_intro__billet {
  z-index: 1;
  padding: 40px 20px 20px 0;
}
.hairdress_intro__billet::before {
  content: "";
  position: absolute;
  display: block;
  top: 0;
  left: 5px;
  transform: translateX(-100%);
  width: 100vw;
  height: 100%;
  background-color: var(--dark);
  z-index: -1;
}
.hairdress_intro__block {
  position: relative;
  padding: 40px 15px 5px 130px;
}
.hairdress_intro__block::before {
  content: "";
  position: absolute;
  display: block;
  width: 100vw;
  height: calc(100% + 60px);
  right: -30%;
  bottom: 0;
  background: var(--lightgray);
  z-index: -1;
}
.hairdress_intro__list li {
  position: relative;
  padding-top: 20px;
  margin-bottom: 30px;
}
.hairdress_intro__list li::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 30px;
  height: 5px;
  background-color: var(--red);
}
.hairdress_intro__img {
  width: 46%;
}
.hairdress_intro__img img {
  margin-left: auto;
}
.hairdress_spacer {
  position: relative;
  margin-top: -20px;
}
.hairdress_spacer__block {
  width: 52%;
}
.hairdress_spacer__block p {
  max-width: 780px;
  line-height: 1.65em;
}
.hairdress_spacer__block .paragraph-decor::before {
  width: 100px;
}
.hairdress_color {
  position: relative;
  margin-top: -75px;
}
.hairdress_color__row {
  align-items: center;
}
.hairdress_color__text {
  position: relative;
  width: 52%;
  padding: 55px 0 35px;
}
.hairdress_color__text::before {
  content: "";
  position: absolute;
  display: block;
  width: 150vw;
  height: 100%;
  background-color: var(--lightgray);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: -1;
}
.hairdress_color__text p {
  line-height: 1.55em;
  max-width: 750px;
}
.hairdress_color__text h2 {
  text-align: left;
  margin: 0 0 45px;
}
.hairdress_color__img {
  width: 40%;
}
.hairdress_color__img img {
  margin: 0 auto;
}
@media screen and (max-width: 1400px) {
  .hairdress_spacer {
    margin-top: 20px;
  }
  .hairdress_color {
    margin-top: -45px;
  }
  .hairdress_intro__block {
    padding-left: 65px;
  }
  .hairdress h1 {
    margin-bottom: 45px;
  }
}
@media screen and (max-width: 1200px) {
  .hairdress_spacer {
    margin-top: 40px;
  }
  .hairdress_intro__block {
    padding-top: 30px;
    padding-left: 25px;
  }
  .hairdress_color__text h2 {
    margin-bottom: 30px;
  }
  .hairdress_color {
    margin-top: -30px;
  }
}
@media screen and (max-width: 992px) {
  .hairdress {
    padding-bottom: 50px;
  }
  .hairdress_spacer__block {
    width: 100%;
  }
  .hairdress_color {
    margin-top: 30px;
  }
  .hairdress_intro__list li {
    padding-top: 15px;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 768px) {
  .hairdress_intro__img,
  .hairdress_color__img {
    width: 100%;
    order: -1;
    margin-bottom: 25px;
  }
  .hairdress_intro__img img {
    margin: 0 auto;
  }
  .hairdress_intro__text,
  .hairdress_color__text {
    width: 100%;
  }
  .hairdress_intro__text {
    padding-top: 0px;
  }
  .hairdress_color__text {
    padding-top: 35px;
  }
  .hairdress_intro__block {
    padding-left: 0;
  }
  .hairdress_intro__billet::before,
  .hairdress_intro__block::before {
    left: 50%;
    transform: translateX(-50%);
  }
  .hairdress_color__text h2 {
    margin-bottom: 30px;
  }
}
/* end Страница Парикмахерская */
